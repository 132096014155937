<template>
	<div class="label area-inset-top">
		<nut-navbar @on-click-back="event.back" title="添加备注" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		    <template #right>
				<span style="font-size: 12px;color: #6c95ea;" @click="event.save">保存</span>
		    </template>
		</nut-navbar>
		<div class="page">
			<QuillEditor v-model:content="state.content" content-type="html" theme="snow" @ready="event.getDetail($event)"></QuillEditor>
			<!-- <QuillEditor :options="state.editorOption" contentType="html" ref="QuillEditorRef" class="ql-editor" /> -->
		</div>
	</div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, toRefs, ref } from 'vue';
import { Toast,Dialog } from '@nutui/nutui';

 
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
	export default {
		components:{
			QuillEditor
		},
		setup() {
			const QuillEditorRef = ref(null)
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId: '',
				user:[],
				content:'',
				remarks: ''
			})
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
			})
			const event = {
				back(){
					proxy.$router.go(-1)
				},
				updateData(e){
					state.content = e
				},
				save(){
					event.editProjectDetails(state.content,6)
				},
				editProjectDetails(text,type){
					proxy.$axios.editProjectDetails({operateUserId:state.user.userId,projectDetailsId:state.projectId,text:text,type:type,originalText:state.remarks}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							proxy.$router.go(-1)
						}
					})
				},
				getMsg(val){
				  state.msg = val
				},
				getDetail(e){
					proxy.$axios.projectDetailsQuery(proxy.$route.query.id).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.remarks = res.data.data.projectDetails.remarks
							e.container.querySelector('.ql-blank').innerHTML = res.data.data.projectDetails.remarks
						}
					})
				},
			}
			return { state,event,toRefs,QuillEditor };
		}
	}
</script>

<style>
	.ql-snow .ql-container {
		height: 300px;
		line-height: normal;
		width: auto;
	}

	.ql-snow span.ql-size {
		max-width: 80px !important;
	}

	.ql-snow .ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}

	.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}

	.ql-snow .ql-tooltip[data-mode="video"] {
		left: 0 !important;
	}

	.ql-snow .ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
		content: "12px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
		content: "13px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
		content: "14px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
		content: "16px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
		content: "17px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
		content: "18px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="19px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before {
		content: "19px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
		content: "20px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="21px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before {
		content: "21px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
		content: "22px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="23px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before {
		content: "23px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
		content: "24px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="25px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before {
		content: "25px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
		content: "26px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="27px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before {
		content: "27px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
		content: "28px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="29px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="29px"]::before {
		content: "29px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
		content: "30px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="31px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="31px"]::before {
		content: "31px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
		content: "32px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="33px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="33px"]::before {
		content: "33px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="34px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="34px"]::before {
		content: "34px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="35px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="35px"]::before {
		content: "35px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
		content: "36px";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}
	.label{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		background-color: #f7f7f7;
	}
	.page{
		height: calc(100vh - 100px);
		height: calc(var(--vh, 1vh) * 100 - 100px);
	}
</style>