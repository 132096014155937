<template>
	<div class="label area-inset-top">
		<nut-navbar @on-click-back="event.back" title="财务" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		    <template #right>
				<span style="font-size: 12px;color: #6c95ea;" @click="event.save">保存</span>
		    </template>
		</nut-navbar>
		<div class="cw">
			<div class="laberItem">
				<span>融资需求</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.list[0].financingDemand"  />万元</div>
			</div>
			<div class="laberItem">
				<span>项目估值</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.list[0].projectValuation"  />万元</div>
			</div>
			<div class="laberItem">
				<span>拟投资金额</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.list[0].proposedInvestmentAmount"  />万元</div>
			</div>
			<div class="laberItem" style="border: none;">
				<span>占股比例</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.list[0].proportion"  />%</div>
			</div>
			<div class="labelTit" style="text-align: left;"><span class="line_icon"></span>简要财务</div>
			<div class="laberItem">
				<span>总资产</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.listF[0].totalAssets"  />万元</div>
			</div>
			<div class="laberItem">
				<span>净资产</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.listF[0].netAssets"  />万元</div>
			</div>
			<div class="laberItem">
				<span>营业收入</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.listF[0].operatingRevenue"  />万元</div>
			</div>
			<div class="laberItem">
				<span>毛利润</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.listF[0].grossProfit"  />万元</div>
			</div>
			<div class="laberItem" style="border: none;">
				<span>净利润</span><div><input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.listF[0].netProfit"  />万元</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
import { Toast,Dialog } from '@nutui/nutui';
	export default {
		name:'itemCW',
		setup() {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId: '',
				user:[],
				list:[{}],
				listF:[{}]
			})
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
				event.detail()
			})
			const event = {
				input(el){
					if(el.target.value.length>=14){
						el.target.value = el.target.value.slice(0,14)
					}
				},
				back(){
					proxy.$router.go(-1)
				},
				detail(){
					proxy.$axios.projectDetailsQuery(state.projectId).then(res=>{
						if(res.data.errorCode == '00000'){
							state.list = res.data.data.projectCapitalizeInfoList
							console.log(state.list)
							delete state.list[0].createTime
							delete state.list[0].isDelete
							delete state.list[0].updateTime
							delete state.list[0].projectDetailsId
							state.listF = res.data.data.projectFinanceInfoList
							delete state.listF[0].createTime
							delete state.listF[0].isDelete
							delete state.listF[0].updateTime
							delete state.listF[0].projectDetailsId
						}
					})
				},
				save(){
					let reg = /^\d{1,10}$|^\d{1,10}[.]\d{1,4}$/
					if(state.list[0].financingDemand && !reg.test(state.list[0].financingDemand)){
						Toast.warn('请输入正确的融资需求')
						return
					}
					if(state.list[0].projectValuation && !reg.test(state.list[0].projectValuation)){
						Toast.warn('请输入正确的项目估值')
						return
					}
					if(state.list[0].proposedInvestmentAmount && !reg.test(state.list[0].proposedInvestmentAmount)){
						Toast.warn('请输入正确的拟投资金额')
						return
					}
					if(state.list[0].totalAssets && !reg.test(state.list[0].totalAssets)){
						Toast.warn('请输入正确的总资产')
						return
					}
					if(state.list[0].netAssets && !reg.test(state.list[0].netAssets)){
						Toast.warn('请输入正确的净资产')
						return
					}
					if(state.list[0].operatingRevenue && !reg.test(state.list[0].operatingRevenue)){
						Toast.warn('请输入正确的营业收入')
						return
					}
					if(state.list[0].grossProfit && !reg.test(state.list[0].grossProfit)){
						Toast.warn('请输入正确的毛利润')
						return
					}
					if(state.list[0].netProfit && !reg.test(state.list[0].netProfit)){
						Toast.warn('请输入正确的净利润')
						return
					}
					proxy.$axios.editProjectCapitalizeInfoAndFinanceInfo({projectDetailsId:state.projectId,projectCapitalizeInfoList:state.list,projectFinanceInfoList:state.listF}).then(res =>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							proxy.$router.go(-1)
						}
					})
				},
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.laberItem input{
		text-align: right;
		border: none;
		height: 35px;
		outline: none;
		line-height: 35px;
		background-color: transparent;
	}
	.laberItem textarea{
		text-align: left;
		border: none;
		outline: none;
		background-color: #eee;
		width: calc(100% - 20px);
		padding: 0 10px;
		border-radius: 5px;
	}
</style>