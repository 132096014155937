<template>
  <div class="">
		<div class="item_list" v-for="pro in state.listObj" @click="event.detail(pro)">
			<div class="item_title">{{pro.projectDetails.projectName}}</div>
			<div class="item_tab_span" style="text-align: left; overflow-x: scroll; white-space: nowrap;">
				<span v-for="lvl in pro.projectLabelVoList">{{lvl.labelName}}</span>
			</div>
			<div class="item_txt">
				{{pro.projectDetails.introduction}}
			</div>
			<div class="item_date">
				<nut-avatar-group zIndex="right" span="-16" max-count="3" max-color="#fff" max-bgColor="#6692EF" v-if="JSON.stringify(pro.userRespDto) != 'null' && JSON.stringify(pro.userRespDto) != '[]'">
					<nut-avatar size="small" v-for="item in pro.userRespDto" :url="item.avatarPath">
					</nut-avatar>
				</nut-avatar-group>
				<!-- <span class="item_head" v-if="JSON.stringify(pro.userRespDto) != 'null' && JSON.stringify(pro.userRespDto) != '[]'">
					<img v-if="pro.userRespDto[0].avatarPath" :src="pro.userRespDto[0].avatarPath">
				</span> -->
				<span class="item_head" v-else></span>
				<span class="item_right">{{pro.projectDetails.projectApprovalTime}}</span>
			</div>
			<div :class="['item_label',state.tabList[pro.projectDetails.projectProgress].class]">{{state.tabList[pro.projectDetails.projectProgress].txt}}</div>
		</div>
  </div>
</template>
<script>
	import { reactive, onMounted, getCurrentInstance, onUpdated } from 'vue'
	export default{
		props:{
			listData: Array,
		},
		setup(props){
			const { proxy } = getCurrentInstance()
			const state = reactive({
				listObj: {},
				tabList:[
					{},//[{"code":"0","desc":"全部进度"},{"code":"1","desc":"见面"},{"code":"2","desc":"签NDA"},{"code":"8","desc":"立项"},{"code":"3","desc":"出TS"},{"code":"4","desc":"尽调"},{"code":"5","desc":"投决"},{"code":"6","desc":"投后"},{"code":"7","desc":"暂缓"}]
					{txt:'见面',class:'blueLong'},
					{txt:'签NDA',class:'bule'},
					{txt:'出TS',class:'lan'},
					{txt:'尽调',class:'chenLong'},
					{txt:'投决',class:'chen1'},
					{txt:'投后',class:'chenHou'},
					{txt:'暂缓',class:'hui'},
					{txt:'立项',class:'lanHou'},
					{txt:'立项投票',class:'zi'},
					{txt:'新入库',class:'blueHou'},
				],
			})
			onMounted(()=>{
				state.listObj = props.listData
			})
			onUpdated(()=>{
				state.listObj = props.listData
			})
			const event = {
				detail(item){
					proxy.$router.push({name:'detail',query:{id:item.projectDetails.projectDetailsId}})
				}
			}
			return {
				state,
				event
			}
		},
		
	}
</script>