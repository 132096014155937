<template>
  <div class="">
		<div class="trends_list" v-for="(item,index) in state.listObj">
			<div class="list_tit">{{index}}</div>
			<div class="list_item" v-for="(childItem,childIndex) in item" @click="event.detail(childItem.projectDetailsId,JSON.parse(childItem.changeContent))">
				<div class="item_left">
					<div>
						<img :src="childItem.avatarPath" alt="">
					</div>
				</div>
				<div class="item_right">
					<div class="right_tit">
						<span>{{childItem.nickName}}</span>
						<span>{{childItem.updateTime.split(' ')[1]}}</span>
					</div>
					<!-- 机构动态 -->
					<div v-if="childItem.recordType == 2">
						<div class="right_cont">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									发布了交流笔记:
								</div>
							</div>
							<div class="cont_label">
								<span class="cont_sl" v-html="JSON.parse(childItem.changeContent).text"></span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
					</div>
					<!-- 协议动态 -->
					<div v-if="childItem.recordType == 1">
						<div class="right_cont" v-if="JSON.parse(childItem.changeContent).operationType == '0'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									批示意见:
									<div style="margin: 5px 0;color:#000;font-weight: 600;">
										{{JSON.parse(childItem.changeContent).leaveMessage}}
									</div>
								</div>
							</div>
							<!-- <div class="item_tab_span" style="margin: 20px 0;">
								<span v-for="t1 in JSON.parse(childItem.changeContent).text1">{{t1}}</span>
							</div> -->
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).projectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).projectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).projectProgress].txt}}</span>
								<span class="cont_tit">{{childItem.projectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="JSON.parse(childItem.changeContent).operationType == '1'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div v-if="JSON.parse(childItem.changeContent).voteResult == 0">
									同意 协议:
									<div style="margin: 5px 0;color:#000;font-weight: 600;">
										{{JSON.parse(childItem.changeContent).leaveMessage}}
									</div>
								</div>
								<div v-else-if="JSON.parse(childItem.changeContent).voteResult == 1">
									不同意 协议:
									<div style="margin: 5px 0;color:#000;font-weight: 600;">
										{{JSON.parse(childItem.changeContent).leaveMessage}}
									</div>
								</div>
								<div v-else>
									:
									<div style="margin: 5px 0;color:#000;font-weight: 600;">
										{{JSON.parse(childItem.changeContent).leaveMessage}}
									</div>
								</div>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).projectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).projectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).projectProgress].txt}}</span>
								<span class="cont_tit">{{childItem.projectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="JSON.parse(childItem.changeContent).operationType == '2'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div style="margin-bottom: 10px;">
									查看了协议
								</div>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).projectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).projectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).projectProgress].txt}}</span>
								<span class="cont_tit">{{childItem.projectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="JSON.parse(childItem.changeContent).operationType == '3'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div style="margin-bottom: 10px;">
									更新了协议《{{JSON.parse(childItem.changeContent).fileName}}》，第{{JSON.parse(childItem.changeContent).fileVersion}}版
								</div>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).projectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).projectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).projectProgress].txt}}</span>
								<span class="cont_tit">{{childItem.projectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="JSON.parse(childItem.changeContent).operationType == '4'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div style="margin-bottom: 10px;">
									定稿
								</div>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).projectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).projectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).projectProgress].txt}}</span>
								<span class="cont_tit">{{childItem.projectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="JSON.parse(childItem.changeContent).operationType == '5'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div style="margin-bottom: 10px;">
									作废协议
								</div>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).projectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).projectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).projectProgress].txt}}</span>
								<span class="cont_tit">{{childItem.projectName}}</span>
							</div>
						</div>
					</div>
					<div v-else-if="childItem.recordType == 0">
					<!-- 0-新增了项目,1-新增了项目标签,2-修改了项目名称,3-修改了项目简介,4-修改了项目备注,5-修改了项目进度,6-上次了附件，7-发起投决投票，8-项目用户投票，9-项目用户评论，10-修改项目分类， -->
						<div class="right_cont" v-if="childItem.changeOperation == '0'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									新增了项目:
								</div>
							</div>
							<div class="item_tab_span" style="margin: 20px 0;">
								<span v-for="t1 in JSON.parse(childItem.changeContent).text1">{{t1}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '1'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									新增了项目标签:
								</div>
							</div>
							<div class="item_tab_span" style="margin: 20px 0;">
								<span>{{JSON.parse(childItem.changeContent).LabelName}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '2'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									修改了项目名称:
								</div>
							</div>
							<div class="cont_label">原项目名称为：{{JSON.parse(childItem.changeContent).oldProjectName}}，修改后项目名称为：{{JSON.parse(childItem.changeContent).newProjectName}}</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '3'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									修改了项目简介:
								</div>
							</div>
							<div class="cont_label">{{JSON.parse(childItem.changeContent).newProjectIntroduction}}</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '4'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									修改了项目备注:
								</div>
							</div>
							<div class="cont_label">{{JSON.parse(childItem.changeContent).newRemarks}}</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '5'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									修改了项目进度
								</div>
							</div>
							<div class="cont_tit" style="margin: 10px 0 15px;" v-if="JSON.parse(childItem.changeContent).oldProgress && JSON.parse(childItem.changeContent).newProgress">
								<span :class="['trends_tab1',state.tabList[JSON.parse(childItem.changeContent).oldProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).oldProgress].txt}}</span>
								<span class="jiantou_icon"></span>
								<span :class="['trends_tab1',state.tabList[JSON.parse(childItem.changeContent).newProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).newProgress].txt}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '6'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									{{JSON.parse(childItem.changeContent).text}}
								</div>
							</div>
							<div class="cont_label">
								<span  :class="{'file_icon':true,
								'word_icon':['txt','doc','docx','log'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'pdf_icon':['pdf'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1
								}"></span>
								<span style="width: calc(100% - 31px);" class="huanhang">{{JSON.parse(childItem.changeContent).FileName}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '7'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div v-if="JSON.parse(childItem.changeContent).ProjectProgress == '9'">
									发起立项投票
								</div>
								<div v-else>
									发起投决投票
								</div>
								<div class="cont_txt" style="display: flex;">
									投票剩余时间：
									<nut-countdown :end-time="JSON.parse(childItem.changeContent).endTime" ></nut-countdown>
								</div>
							</div>
							<div class="cont_label">
								当前投票状态：
								<span class="trends_tab2 chen_bg" v-if="JSON.parse(childItem.changeContent).ProjectProgress == '9'">同意立项:{{JSON.parse(childItem.changeContent).voteResult['1'] || 0}}</span>
								<span class="trends_tab2 chen_bg" v-else>投资:{{JSON.parse(childItem.changeContent).voteResult['1'] || 0}}</span>
								<span class="vs"></span>
								<span class="trends_tab2 longBlue_bg">暂缓:{{JSON.parse(childItem.changeContent).voteResult['2'] || 0}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '8'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									{{JSON.parse(childItem.changeContent).text}}
									<span v-if="JSON.parse(childItem.changeContent).voteModifyReqDto.voteType == 1 && JSON.parse(childItem.changeContent).ProjectProgress == '9'" class="trends_tab2 chen_bg">同意立项</span>
									<span v-else-if="JSON.parse(childItem.changeContent).voteModifyReqDto.voteType == 1" class="trends_tab2 chen_bg">投资</span>
									<span v-if="JSON.parse(childItem.changeContent).voteModifyReqDto.voteType == 2" class="trends_tab2 longBlue_bg">暂缓</span>
								</div>
								<div class="cont_txt" style="display: flex;">
									投票剩余时间：
									<nut-countdown :end-time="JSON.parse(childItem.changeContent).endTime" ></nut-countdown>
								</div>
							</div>
							<div class="cont_label">
								当前投票状态：
								<span class="trends_tab2 chen_bg" v-if="JSON.parse(childItem.changeContent).ProjectProgress == '9'">同意立项:{{JSON.parse(childItem.changeContent).voteResult['1'] || 0}}</span>
								<span class="trends_tab2 chen_bg" v-else>投资:{{JSON.parse(childItem.changeContent).voteResult['1'] || 0}}</span>
								<span class="vs"></span>
								<span class="trends_tab2 longBlue_bg">暂缓:{{JSON.parse(childItem.changeContent).voteResult['2'] || 0}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						
						<div class="right_cont" v-if="childItem.changeOperation == '9'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									评论了项目:
								</div>
							</div>
							<div class="cont_label">
								{{JSON.parse(childItem.changeContent).Comment}}
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '10'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									修改了项目分类
								</div>
							</div>
							<div class="cont_tit" style="margin: 10px 0 15px;">
								<span :class="['trends_tab1',state.loadingList[JSON.parse(childItem.changeContent).oldCategory].class]">{{state.loadingList[JSON.parse(childItem.changeContent).oldCategory].txt}}</span>
								<span class="jiantou_icon"></span>
								<span :class="['trends_tab1',state.loadingList[JSON.parse(childItem.changeContent).newCategory].class]">{{state.loadingList[JSON.parse(childItem.changeContent).newCategory].txt}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
						<div class="right_cont" v-if="childItem.changeOperation == '12'">
							<div class="cont_tit" style="display: flex;justify-content: space-between;">
								<div>
									{{JSON.parse(childItem.changeContent).text}}
								</div>
							</div>
							<div class="cont_label">
								<span  :class="{'file_icon':true,
								'word_icon':['txt','doc','docx','log'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1,
								'pdf_icon':['pdf'].indexOf(JSON.parse(childItem.changeContent).FileType) != -1
								}"></span>
								<span style="width: calc(100% - 31px);" class="huanhang">{{JSON.parse(childItem.changeContent).FileName}}</span>
							</div>
							<div class="cont_status">
								<span v-if="JSON.parse(childItem.changeContent).ProjectProgress" :class="['trends_tab',state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].class]">{{state.tabList[JSON.parse(childItem.changeContent).ProjectProgress].txt}}</span>
								<span class="cont_tit">{{JSON.parse(childItem.changeContent).ProjectName}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, onUpdated, defineEmits } from 'vue'
	export default{
		props:{
			listData: Array,
		},
		setup(props){
			const { proxy } = getCurrentInstance()
			const state = reactive({
				listObj: {},
				tabList:[//分类
					{},
					{txt:'见面',class:'blueLong'},
					{txt:'签NDA',class:'bule'},
					{txt:'出TS',class:'lan'},
					{txt:'尽调',class:'chenLong'},
					{txt:'投决',class:'chen1'},
					{txt:'投后',class:'chenHou'},
					{txt:'暂缓',class:'hui'},
					{txt:'立项',class:'lanHou'},
					{txt:'立项投票',class:'zi'},
					{txt:'新入库',class:'blueHou'},
				],
				loadingList:[//进度
					{},
					{txt:'新键',class:'chen_bg'},//新项目
					{txt:'重点',class:'lan'},
					{txt:'其他',class:'chen_bg'},
					{txt:'归档',class:'lan'},
					{txt:'跟进中',class:'longBlue_bg'},//跟进中
				],
			})
			onUpdated(()=>{
				state.listObj = props.listData
			})
			const event = {
				detail(id,content){
					if(content.organizationId){
						proxy.$router.push({name:'MechanismDetail',query:{id:content.organizationId}})
					}else{
						proxy.$router.push({name:'detail',query:{id:id}})
					}
				}
			}
			return {
				state,
				event
			}
		},
		
	}
</script>

<style>
	.trends{
		height: calc(100vh - 100px);
		height: calc(var(--vh, 1vh) * 100 - 100px);
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		text-align: left;
	}
	.trends .trends_list{
		padding-bottom: 40px;
	}
	.trends_list .list_tit{
		height: 46px;
		line-height: 46px;
		background-color: #f7f7f7;
		color: 999;
		font-size: 14px;
		padding-left: 10px;
	}
	.trends_list .list_item{
		background-color: #fff;
		display: flex;
		padding: 10px;
		width: calc(100vw - 20px);
	}
	.trends_list .item_left div{
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		border-radius: 20px;
		overflow: hidden;
	}
	.trends_list .item_left div img{
		height: 100%;
		width: 100%;
	}
	.trends_list .item_right{
		width: 100%;
		margin-left: 10px;
		width: calc(100% - 50px);
	}
	.trends_list .right_tit{
		font-size: 16px;
		color: #666;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	}
	.trends_list .right_tit span:last-child{
		font-size: 12px;
		color: #aaa;
	}
	.right_cont{
		background-color: #f7f7f7;
		border-radius: 10px;
		padding: 20px 10px;
	}
	.cont_tit{
		color: #666;
		font-size: 12px;
	}
	.cont_txt{
		font-size: 12px;
		color: #939393;
	}
	.cont_label{
		color: #333;
		font-size: 14px;
		margin: 10px 0;
		overflow: hidden;
	}
	.trends_tab{
		font-size: 12px;
		padding: 5px;
		border-radius: 5px;
		margin-right: 10px;
		color: #fff;
	}
	.trends_tab1{
		color: #fff;
		font-size: 13px;
		border-radius: 5px;
		padding: 5px;
	}
	.trends_tab2{
		display: inline-block;
		color: #fff;
		border-radius: 11px;
		padding: 2px 5px 3px;
	}
	.chen{
		color: #ED9956;
		background-color: #FFF1E5;
	}
	.blue{
		color: #546FD5;
		background-color: #EDEFFF;
	}
	.lan_bg{
		background-color: #47CCAB;
	}
	.blue_bg{
		background-color: #6C95EA;
	}
	.chen_bg{
		background-color: #F58068;
	}
	.longBlue_bg{
		background-color: #46B1EA;
	}
	.vs{
		display: inline-block;
		width: 17px;
		height: 17px;
		background-image: url('../../assets/imgs/vs.png');
		background-size: 100%;
		background-repeat: no-repeat;
		margin: 0 8px;
		vertical-align: middle;
	}
	.jiantou_icon{
		display: inline-block;
		width: 12px;
		height: 12px;
		background-image: url('../../assets/imgs/jian_icon.png');
		background-size: 100%;
		background-repeat: no-repeat;
		margin: 0 10px;
		vertical-align: middle;
	}
	.trends .nut-countdown{
		font-size: 12px;
		line-height: 18px;
	}
	.file_icon{
		display: inline-block;
		width: 26px;
		height: 32px;
		background-size: 100%;
		background-position: top center;
		background-repeat: no-repeat;
		vertical-align: middle;
		margin-right: 5px;
		background-image: url('/src/assets/imgs/other.png');
	}
	.jpg_icon{
		background-image: url('/src/assets/imgs/jpg.png');
	}
	.excel_icon{
		background-image: url('/src/assets/imgs/excel.png');
	}
	.ppt_icon{
		background-image: url('/src/assets/imgs/ppt.png');
	}
	.pdf_icon{
		background-image: url('/src/assets/imgs/pdf.png');
	}
	.word_icon{
		background-image: url('/src/assets/imgs//word.png');
	}
	.video_icon{
		background-image: url('/src/assets/imgs/video.png');
	}
	.other_icon{
		background-image: url('/src/assets/imgs/other.png');
	}
	.huanhang{
		display: inline-block;
		word-break: break-word;
		vertical-align: middle;
	}
	.cont_sl{
		display: -webkit-box;
		/* 默认最多显示3行 */
		-webkit-line-clamp: 3;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}
</style>