<template>
	<div class="iframe">
		<nut-navbar @on-click-back="event.back" title="预览" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<div>
			<iframe id="iframe" :src="state.url" v-if="!state.show" style="width:100vw;height:calc(100vh - 40px);border:none;" ref="iframe" frameborder="0"></iframe>
			<img style="width: 100%;height: 100%;" :src="state.url" alt="" v-if="state.show" >
		</div>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, onUpdated, onUnmounted, toRefs,ref } from 'vue'
	export default{
		props:{
			url:String,
			type:String
		},
		setup(props,context){
			const { proxy } = getCurrentInstance()
			const iframe = ref(null)
			const state = reactive({
				id: '',
				url: '',
				show: false
			})
			onMounted(()=>{
				if(['jpg','jpeg','png','gif','bmp','csv'].indexOf(props.type) != -1){
					state.url = props.url
					state.show = true
				}else{
					state.url = props.url
				}
				
			})
			const event = {
				setAclPrivateByInfoId(id){
					proxy.$axios.setAclPrivateByInfoId(id).then(res=>{
						if(res.data.errorCode == '00000'){
							
						}
					})
				},
				back(){
					context.emit('fileClose',{fileBox: false,fileUrl:'',fileType:''})
				}
			}
			return {
					state,
					event,
					iframe
				}
			},
			
		}
</script>

<style>
	.iframe{
		width: 100vw;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		position: fixed;
		top: 0;
		left: 0;
		background-color: #fff;
		z-index: 100;
	}
	#iframe{
		width: 100vw;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}
	#iframe img{
		width: 100%;
	}
	.tit{
		width: 100vw;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}
</style>