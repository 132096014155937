<template>
	<div class="item area-inset-top">
		<div class="item_top">
			<div class="top_left">
				<!-- <div @click="event.sumClick" v-if="state.tab1value != 4 && state.tab1value != 5 && state.tab1value != 2">
					{{state.jindu}}<span class="xiala_icon"></span>
				</div> -->
				<div class="item_tab">
					<nut-tabs title-scroll title-gutter="10" background="#fff" color="#4475DF">
						<template v-slot:titles>
							<div class="nut-tabs__titles-item" @click="event.getSum(0,item.paneKey)" v-show="item.paneKey != '2' || (item.paneKey == '2' && state.tpShow)" :class="{active:state.jinduCode==item.paneKey}" :key="item.paneKey" v-for="item in state.list4">
								<!-- <span v-if="item.icon" :class="item.icon"></span> -->
								<span class="nut-tabs__titles-item__text">{{item.title}}</span>
								<span class="nut-tabs__titles-item__line"></span>
							</div>
						</template>
					</nut-tabs>
				</div>
			</div>
			<div class="top_right"><img style="margin: 0;" class="serach_icon" src="../assets/imgs/serach_icon.png" alt="" @click="event.search"><img @click="event.add" class="add_icon" src="../assets/imgs/add_icon.png" alt=""></div>
		</div>
		<div class="item_content">
			<div class="item_tab item_tab1" ref="tab">
				<nut-tabs title-scroll title-gutter="10" background="#fff" color="#4475DF">
					<template v-slot:titles>
						<!--  -->
						<div v-if="state.showTab.indexOf(state.jinduCode) == -1" class="nut-tabs__titles-item" @click="event.tabClick(item)" :class="{active:state.tab1value==item.code}" :key="item.code" v-for="item in state.porSum">
							<span class="nut-tabs__titles-item__text">{{item.desc}} {{item.number}}</span>
							<span class="nut-tabs__titles-item__line"></span>
						</div>
					</template>
					<nut-tabpane id="customScroll">
						<template v-slot:default>
							<nut-infiniteloading
								  load-txt="加载中..."
								  pull-txt="   "
								  load-more-txt="没有啦～"
								  container-id="customScroll"
								  :use-window="false"
								  :is-open-refresh="true"
								  :has-more="state.customHasMore"
								  load-icon="loading1"
								  pull-icon="loading1"
								  @load-more="customLoadMore"
								  @refresh="refresh"
								>
								<xm-component :listData="state.list"></xm-component>
							</nut-infiniteloading>
						</template>
					</nut-tabpane>
				</nut-tabs>
			</div>
			<div class="right_p" @click="state.showPopLeft = true" v-if="state.showTab.indexOf(state.jinduCode) == -1">
				<img style="margin: 0;" class="zan_icon" src="../assets/imgs/zan_icon.png" alt="">
			</div>
		</div>
		<nut-popup position="right" teleport="#app" :teleport-disable="true" v-model:visible="state.showPopLeft">
			<div class="pop_left_content">
				<div v-for="(item,index) in state.porSum" @click="event.popClickR(item,index)">
					{{item.desc}} {{item.number}}
				</div>
			</div>
		</nut-popup>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.showPop">
			<div class="popup_li" v-for="(item,index) in state.porSum" :key="item.code" @click="event.popClick(index,item)">
				{{item.desc}}({{item.number}})
			</div>
		</nut-popup>
	</div>
	
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs,watch, onCreated,onActivated, onBeforeMount,ref } from 'vue';
	import {useRouter} from 'vue-router'
	import xmComponent from '@/view/item/list'
	export default {
		name: 'index',
		components:{
			xmComponent
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const tab = ref(null)
			const router = useRouter()
			const state = reactive ({
				tpShow: true,
				showTab: [2,'10','11'],
				showPopLeft: false,
				tab1value: '0',
				user: {},
				customHasMore: true,
				showPop: false,
				jindu: '全部进度',
				jinduIndex: 0,
				jinduCode:'0',
				cur:1,
				list4: [
					{
						title:'我参与的',
						paneKey: '0',
						icon: 'home'
					},
					{
						title:'我关注的',
						paneKey: '1',
						icon: 'guanzhu'
					},
					{
						title:'投票中',
						paneKey: '2',
						icon: 'toupiao'
					},
					{
						title:'重点',
						paneKey: '7',
						icon: 'zhongdian'
					},
					{
						title:'跟进中',
						paneKey: '9',
						icon: 'genjinzhong'
					},
					{
						title:'新项目',
						paneKey: '6',
						icon: 'newItem'
					},
					{
						title:'其他',
						paneKey: '10',
						icon: 'newItem'
					},
					{
						title:'归档',
						paneKey: '11',
						icon: 'genjinzhong'
					},
					// {
					// 	title:'暂缓',
					// 	paneKey: '4',
					// 	icon: 'zanfan'
					// },
					// {
					// 	title:'投后',
					// 	paneKey: '5',
					// 	icon: 'touhou'
					// },
					// {
					// 	title:'投票中',
					// 	paneKey: '2',
					// 	icon: 'toupiao'
					// }
				],
				list:[],
				porSum:[],
				tabLeft: 0
			});
			onActivated(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				if(state.user){
					if(router.currentRoute.value.query.isBack != 'false'){
						event.getVoteListByUserId(0,0,function(){})
					}else{
						event.getSum(state.tab1value,state.jinduCode)
					}
				}else{
					proxy.$router.push('/login')
				}
			})
			const customLoadMore = done => {
				state.cur++
				event.getList(state.cur,10,state.tab1value,state.jinduCode,done)
			}
			const refresh = done => {
				state.cur = 1
				state.list = []
				if(state.jinduCode == 2){
					event.getVoteListByUserId(0,0,done)
					return
				}
				event.getList(state.cur,10,state.tab1value,state.jinduCode,done)
			}
			const event = {
				popClickR(item,index){
					state.showPopLeft = false
					event.tabClick(item)
					tab.value.children[0].children[0].scrollTo({left:0,top:0,behavior: 'smooth'})
					let rect = tab.value.children[0].children[0].children[index].getBoundingClientRect()
					state.tabLeft = rect.left+rect.width
					tab.value.children[0].children[0].scrollTo({left:state.tabLeft,top:0,behavior: 'smooth'})
				},
				send(){
					Notification.requestPermission().then((result) => {
					    if (result === "granted") {
					      navigator.serviceWorker.ready.then((registration) => {
					        registration.showNotification("Vibration Sample", {
					          body: "Buzz! Buzz!"
					        });
					      });
					    }
					  });
				},
				tabClick(item){
					state.cur = 1
					state.tab1value=item.code
					event.getList(1,10,item.code,state.jinduCode,function(){})
				},
				getList(cur,size,projectProgress,type,fun){
					proxy.$axios.projectListQueryPage({currentPage:cur,pageSize:size,projectProgress:projectProgress,type:type,userId:state.user.userId,userRole:state.user.role}).then(res =>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data
							if(obj.pages <= cur){
								state.customHasMore = false
							}else{
								state.customHasMore = true
							}
							if(cur == 1){
								state.list = obj.list
							}else{
								state.list = state.list.concat(obj.list)
							}
							fun()
						}
					})
				},
				getSum(projectProgress,type){
					state.tab1value = projectProgress
					state.jinduCode = type
					if(type == 2){
						event.getVoteListByUserId(0,0,function(){})
						return
					}
					state.cur = 1
					proxy.$axios.projectSummary({projectProgress:projectProgress,type:type,userId:state.user.userId,userRole:state.user.role}).then(res =>{
						if(res.data.errorCode == '00000'){
							state.porSum = res.data.data
							event.getList(1,10,projectProgress,type,function(){})
							state.jindu = state.porSum[state.jinduIndex].desc+'('+state.porSum[state.jinduIndex].number+')'
						}
					})
				},
				getVoteListByUserId(status,type,fun){
					proxy.$axios.getVoteListByUserId({status:status,type:type,userId:state.user.userId,userRole:state.user.role}).then(res =>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data
							if(obj.length){
								state.jinduCode = 2
								state.list = obj
								state.customHasMore = false
							}else{
								state.tpShow = false
								event.getSum(0,0)
							}
							fun()
						}
					})
				},
				sumClick(){
					state.showPop = true
				},
				popClick(index,item){
					state.showPop = false
					state.jinduIndex = index
					state.jinduCode = item.code
					this.getList(1,10,item.code,0,function(){})
				},
				detail(item){
					
				},
				search(){
					proxy.$router.push({name:'search'})
				},
				add(){
					proxy.$router.push({name:'itemAdd'})
				}
			}
			return { state,event,toRefs,customLoadMore,refresh,tab };
		}
	};
</script>

<style>
	.item{
		overflow: hidden;
		font-size: 14px;
		background-color: #5484ea;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}
	.item_content{
		background-color: #f7f7f7;
		position: relative;
	}
	.item_top{
		justify-content: space-between;
		padding: 10px 10px 10px;
		background-image: url('../assets/imgs/topBg.png');
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100% 70px;
		display: flex;
		color: #fff;
		align-items: center;
	}
	.item_top div img{
		margin-left: 10px;
	}
	.top_left{
		width: calc(100% - 70px);
	}
	.serach_icon{
		width: 25px;
		height: 23px;
	}
	.add_icon{
		width: 24px;
		height: 24px;
	}
	.zan_icon{
		width: 24px;
		height: 24px;
	}
	.item_tab{
		
	}
	.item_tab1{
		/* margin: 0px 10px; */
		position: relative;
		/* top: -19px; */
	}
	.item_tab .nut-tabs__titles{
		height: 40px;
		border-radius: 10px;
		overflow-y: visible;
	}
	.item_tab .nut-tabs__titles-item{
		width: 24px;
		color: #777;
		margin: 0 5px;
	}
	.item_tab .nut-tabs__titles-item.active{
		color: #4475DF;
	}
	.item_tab .nut-tabs__titles-item.active .nut-tabs__titles-item__line{
		background-image: url('../assets/imgs/xia.png');
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100%;
		width: 65px;
		height: 3px;
		margin-top: 18px;
		position: absolute;
		bottom: 0px;
	}
	.item_tab .nut-tabs__titles-item span:first-child{
		display: inline-block;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 2px;
	}
	
	/* 新增开始 */
	.item_tab .nut-tabs__titles-item{
		min-width: auto;
		margin: 0 8px;
	}
	.item_tab .nut-tabs__titles-item.active .nut-tabs__titles-item__line{
		width: 60% !important;
		height: 2px !important;
		background: #4475DF;
	}
	.item_tab .nut-tabs__titles{
		background: transparent !important;
		height: 25px;
		padding: 0;
		border-radius: 0;
	}
	.item_tab1 .nut-tabs__titles{
		background: transparent !important;
		height: auto;
		padding: 0;
		border-radius: 0;
		padding-right: 40px;
	}
	.item_tab1 .nut-tabs__titles-item{
		background: #eee;
		border-radius: 6px;
		padding: 4px 8px;
		color: #333;
		margin: 0 3px;
		font-size: 12px !important;
	}
	.item_tab1 .nut-tabs__titles-item.active{
		background: #4475DF;
		color: #fff;
	}
	.item_tab1 .nut-tabs__titles-item.active .nut-tabs__titles-item__line{
		background: transparent;
	}
	.item{
		background-color: #f7f7f7;
	}
	.item_top{
		background: none;
		padding: 10px 0px 10px;
	}
	.top_right{
		padding-right: 5px;
	}
	.right_p{
		position: absolute;
		right: 0;
		top: 0px;
		width: 40px;
		height: 26px;
		background-color: #f7f7f7;
	}
	.pop_left_content{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		width: 30vw;
		display: flex;
		flex-direction: column;
		padding: 0;
	}
	.pop_left_content>div{
		border-bottom: 1px solid #2c3e50;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
	.pop_left_content>div:last-child{
		border-bottom: none;
	}
	/* 新增结束 */
	
	
	.home{
		background-image: url('../assets/imgs/home.png');
		width: 15px;
		height: 15px;
	}
	.active .home{
		background-image: url('../assets/imgs/home_on.png');
	}
	.genjinzhong{
		background-image: url('../assets/imgs/genjinzhong.png');
		width: 15px;
		height: 15px;
	}
	.active .genjinzhong{
		background-image: url('../assets/imgs/genjinzhong_on.png');
	}
	.newItem{
		background-image: url('../assets/imgs/newItem.png');
		width: 13px;
		height: 15px;
	}
	.active .newItem{
		background-image: url('../assets/imgs/newItem_on.png');
	}
	.zhongdian{
		background-image: url('../assets/imgs/zhongdian.png');
		width: 15px;
		height: 14px;
	}
	.active .zhongdian{
		background-image: url('../assets/imgs/zhongdian_on.png');
	}
	.touhou{
		background-image: url('../assets/imgs/touhou.png');
		width: 15px;
		height: 13px;
	}
	.active .touhou{
		background-image: url('../assets/imgs/touhou_on.png');
	}
	.toupiao{
		background-image: url('../assets/imgs/toupiao.png');
		width: 15px;
		height: 15px;
	}
	.active .toupiao{
		background-image: url('../assets/imgs/toupiao_on.png');
	}
	.guanzhu{
		background-image: url('../assets/imgs/guanzhu.png');
		width: 15px;
		height: 15px;
	}
	.active .guanzhu{
		background-image: url('../assets/imgs/guanzhu_on.png');
	}
	.zanfan{
		background-image: url('../assets/imgs/zanfan.png');
		width: 15px;
		height: 15px;
	}
	.active .zanfan{
		background-image: url('../assets/imgs/zanfan_on.png');
	}
	.item_tab1 .nut-tabpane{
		background-color: transparent;
		height: calc(100vh - 140px);
		height: calc(var(--vh, 1vh) * 100 - 140px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.item_list{
		text-align: left;
		background: #fff;
		border-radius: 10px;
		padding: 15px;
		position: relative;
		margin-bottom: 10px;
	}
	.item_list .item_title{
		font-size: 16px;
		margin-bottom: 14px;
		font-weight: bold;
	}
	.item_tab_span span{
		background: #EAF1FF;
		color: #4E7EE4;
		padding: 4px;
		border-radius: 4px;
		font-size: 12px;
		margin-right: 6px;
		margin-bottom: 5px;
		display: inline-block;
	}
	.item_list .item_txt{
		color: #939393;
		font-size: 14px;
		margin: 13px 0 19px 0;
	}
	.item_list .item_date{
		color: #aaa;
		font-size: 13px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.item_date .item_head{
		width: 27px;
		height: 27px;
		border-radius: 14px;
		margin-right: 4px;
		overflow: hidden;
		display:flex;
		justify-content: center;
	}
	.item_date .item_head img{
		height: 100%;
		width: 100%;
	}
	.item_date .item_label{
		margin:0 10px 0 20px;
	}
	.item_list .item_label{
		border-bottom-left-radius: 11px;
		border-top-right-radius: 11px;
		/* width: 48px; */
		padding: 0px 10px;
		height: 22px;
		line-height: 22px;
		text-align: center;
		font-size: 13px;
		color: #fff;
		position: absolute;
		top: 0;
		right: 0;
	}
	.xiala_icon{
		width: 0;
		border: 8px solid transparent;
		border-top: 8px solid #fff;
		position: relative;
		top: 12px;
		left: 5px;
	}
	.chen1{
		background-color: #F5A667;
	}
	.lan{
		background-color: #6C95EA;
	}
	.bule{
		background-color: #47CCAB;
	}
	.zi{
		background-color: #776CE7;
	}
	.hui{
		background-color: #C2C2C2;
	}
	.chenLong{
		background-color: #F5BE67;
	}
	.chenHou{
		background-color: #E58B2B;
	}
	.blueLong{
		background-color: #64D27E;
	}
	.blueHou{
		background-color: #62D3D2;
	}
	.lanHou{
		background-color: #56AFE8;
	}
	.pop_cont{
		width: 80vw;
	}
	.popup_li{
		height: 40px;
		line-height: 40px;
		color: #4475DF;
		border-bottom: 1px solid #eaeaea;
		font-size: 14px;
	}
	.popup_li:last-child{
		border-bottom: none;
	}
	.routerView .nut-navbar{
		background: #f7f7f7;
	}
	.dialog_reset .nut-button--plain.nut-button--primary{
		color: #5382E7;
		background: #fff;
	}
	.dialog_reset .nut-button--primary{
		background: #5382E7;
		border-color: #5382E7;
	}
</style>