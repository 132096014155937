<template>
	<div class="detailXy area-inset-top">
		<nut-navbar @on-click-back="event.back" :title="state.title" style="box-shadow: none;" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<div class="xy_content">
			<div class="xy_top">
				<div class="xy_name">{{state.detail.protocolName}}</div>
				<div class="xy_user">
					<span :style="'background-image: url('+state.img+');'"></span>
					<span>{{state.userName}}</span>
					<span>{{state.time}}</span>
				</div>
			</div>
			<div class="xy_banben">
				<div v-for="(item,index) in state.xyListT" @click="event.getVoteResult(index)">
					<div v-if="index == 2 || index == 3">{{state.xyList[index-2]}}</div>
					<div v-else-if="index == 1">{{state.xyList[index+1]}}</div>
					<div v-else-if="index == 4"><img style="width:30px;height:30px;" src="../../assets/imgs/icon_history.png" alt=""></div>
					<div v-else-if="index == 0">{{state.detail.partitionCount}}</div>
					<div v-if="index == '4'" style="margin: 0;">{{item}}({{state.detail.historyCount}})</div>
					<div v-else>{{item}}</div>
				</div>
			</div>
			<div class="xy_banbenZ">
				<div>最新版本</div>
				<div>{{state.detail.createTime}}</div>
			</div>
			<div class="xy_list">
				<div class="file_li" v-if="state.detail.protocolFileName" style="padding-bottom: 0;">
					<span :class="{'file_icon':true,
									'word_icon':['txt','doc','docx','log'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'pdf_icon':['pdf'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1
								}"></span>
					<div class="file_cont" style="display: flex;justify-content: space-between;align-items: center;">
						<div class="file_name" style="margin-bottom: 0;">{{state.detail.fileName || state.detail.protocolFileName}}</div><div @click="state.showXy=true" class="xy_btn">查看协议</div>
					</div>
				</div>
				<div class="xy_btns">
					<!-- 0：评审中，1：已定稿，2：失效 -->
					<div @click="state.showYj=true"><span v-if="state.yjData">已发表意见：{{state.yjData}}</span><span v-else>发表处理意见</span></div>
					<div @click="event.upt(0)">更新协议</div>
					<div @click="event.upt(1)">定稿</div>
					<div @click="event.upt(2)"><img style="width:21px;height:21px;vertical-align: middle;" src="../../assets/imgs/icon_delete.png" alt="">作废</div>
				</div>
			</div>
			<div class="xy_bottom">
				<div v-for="item in state.recordList" class="record">
					<div class="record_l" :style="'background-image:url('+item.avatarPath+');'"></div>
					<div class="record_r">
						<div class="record_r_t">
							<div>{{item.nickName}}</div><div>{{item.createTime}}</div>
						</div>
						<div v-if="item.operationType == 0"> <!-- 操作类型     留言 -->
							<div class="xy_tit">批示意见：</div>
							<div class="xy_txt">{{item.leaveMessage}}</div>
						</div>
						<div v-if="item.operationType == 1"> <!-- 操作类型     投票+留言  -->
							<div class="xy_tit"><span v-if="item.voteResult == 0">同意</span><span v-if="item.voteResult == 1">不同意</span>协议：</div>
							<div class="xy_txt">{{item.leaveMessage}}</div>
						</div>
						<div v-if="item.operationType == 2"> <!-- 操作类型     查看 -->
							<div class="xy_tit">查看了协议</div>
						</div>
						<div v-if="item.operationType == 3"> <!-- 操作类型     更新-->
							<div class="xy_tit">更新了协议《{{item.fileName}}》,第{{item.fileVersion}}版</div>
						</div>
						<div v-if="item.operationType == 4"> <!-- 操作类型     定稿-->
							<div class="xy_tit">定稿</div>
						</div>
						<div v-if="item.operationType == 5"> <!-- 操作类型     作废 -->
							<div class="xy_tit">作废协议</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<input type="file" ref="uploadFile" @change="event.fileChange" style="width:0;height:0;opacity: 0;">
		<nut-actionsheet v-model:visible="state.showXy" :menu-items="state.menuItems" @choose="event.choose"></nut-actionsheet>
		<nut-actionsheet v-model:visible="state.showXyHis" :menu-items="state.menuItemsXyHis" @choose="event.xyHisClick"></nut-actionsheet>
		<nut-popup pop-class="userListBox" v-model:visible="state.showTP">
			<div class="userList" v-for="item in state.userList">
				<span class="userA" :style="'background-image: url('+item.avatarPath+');'"></span><span>{{item.nickName}}</span>
			</div>
		</nut-popup>
		<nut-popup pop-class="" style="width: 100%;padding: 20px 0;text-align: left;" position="bottom" v-model:visible="state.showYj">
			<div class="tp_p_cont">
				<div class="tp_Item">
					<div class="tp_v">
						<nut-radiogroup v-model="state.voteResult" direction="horizontal">
							<nut-radio label="2" icon-name="check-normal" icon-active-name="checked">批示意见</nut-radio>
							<nut-radio label="0" icon-name="check-normal" icon-active-name="checked">同意</nut-radio>
							<nut-radio label="1" icon-name="check-normal" icon-active-name="checked">不同意</nut-radio>
						</nut-radiogroup>
					</div>
				</div>
				<div class="tp_Item">
					<div class="tp_v"><nut-textarea max-length="300" rows="4" v-model="state.leaveMessage" limit-show placeholder="请输入处理意见,也可以什么都不说直接提交"/></div>
				</div>
				<div class="tp_Item" style="text-align: right;">
					<span class="yj_btn" @click="state.showYj=false">取消</span>
					<span class="yj_btn" @click="">暂存</span>
					<span class="yj_btn" @click="event.saveProtocolRecord">提交</span>
				</div>
			</div>
		</nut-popup>
		<file-p v-if="state.fileBox" :url="state.fileUrl" :type="state.fileType" @fileClose="event.getStatus"></file-p>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, ref, computed, onUnmounted, nextTick} from 'vue';
	import { Dialog, Toast } from '@nutui/nutui';
	import fileP from '@/view/file/iframe'
	import oss from 'ali-oss'
	export default {
		components:{
			fileP
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const uploadFile = ref(null)
			const state = reactive ({
				oss: {},
				ossF: {},
				fileUrl: '',
				fileBox: false,
				fileType: '',
				height: 0,
				title:'',
				time: '',
				userName:'',
				img:'',
				projectId: 0,
				detail:[],
				recordList: [],
				user:[],
				userList:[],
				showTP: false,
				showXy: false,
				showXyHis:false,
				iosHeight: '44px',
				xyListT:['全部参与','已阅','同意','不同意','历史版本'],
				xyList:{0:0,1:0,2:0},
				menuItems: [
					{
						name: '在线预览本协议',
						color: '#46B1EA'
					},
					{
						name: '下载到手机上',
						color: '#46B1EA'
					}
				],
				menuItemsXyHis:[],
				yjData:''
			});
			const loading = (msg)=>{
				Toast.loading(msg)
			} 
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
				state.title = proxy.$route.query.title
				state.time = proxy.$route.query.time
				state.userName = proxy.$route.query.userName
				state.img = proxy.$route.query.img
				event.getDetail()
			})
			onUnmounted(()=>{
			})
			const event = {
				getLastHandlingOpinions(){
					proxy.$axios.getLastHandlingOpinions({protocolFileId:state.detail.protocolFileId,userId:state.user.userId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.yjData=res.data.data
						}
					})
				},
				saveProtocolRecord(){
					let type = 0
					if(state.voteResult == 2){
						type = 0
					}else if(state.voteResult == 0 || state.voteResult == 1){
						type = 1
					}
					if(state.voteResult == ''){
						Toast.warn('请先选择处理意见')
						return
					}
					proxy.$axios.saveProtocolRecord({projectDetailsId:state.detail.projectDetailsId,protocolFileId:state.detail.protocolFileId,leaveMessage:state.leaveMessage,userId:state.user.userId,voteResult:state.voteResult,operationType:type}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.showYj = false
							Toast.success(res.data.errorMsg)
							event.getDetail()
						}
					})
				},
				upt(index){
					let obj = ['上传协议','定稿','作废协议'];
					Dialog({
						title: '确认"'+obj[index]+'"吗',
						customClass:'dialog_reset',
						onCancel:function(){
							
						},
						onOk:function(){
							if(index==0){
								uploadFile.value.click()
							}else{
								event.delOrFinProtocol(index)
							}
						}
					});
				},
				fileChange(file){
					Toast.loading('上传中...')
					event.getOssStsConfig1(file.target.files[0])
				},
				getOssStsConfig1(file){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.ossF = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: res.data.data.bucketName
							});
							event.putObject(file)
						}
					})
				},
				putObject(data){
					let videoname = proxy.$common.getDateNow('yyyyMMdd')+'/'+state.user.userId+'_'+proxy.$common.getDateNow('yyyyMMddhhmmss')+'_'+data.name
					state.ossF.put(
					  videoname,
					  data
					).then(result => {
					  console.log(result)
					  if(result.res.status == 200){
					    console.log(result)
						event.saveOrUpdateProtocolFile(result,data)
					  }
					}).then(function (r2) {
					  console.log('get success: %j', r2);
					}).catch(function (err) {
					  console.error('error: %j', err);
					});
				},
				saveOrUpdateProtocolFile(data,file){
					let type = file.name.split('.')
					proxy.$axios.saveOrUpdateProtocolFile({protocolId:state.detail.protocolId,userId:state.user.userId,protocolFileType:type[type.length-1],protocolFileName:file.name,fileUploadAddress:data.name,projectDetailsId:state.detail.projectDetailsId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							event.getDetail()
						}
					})
				},
				delOrFinProtocol(status){
					proxy.$axios.delOrFinProtocol({protocolId:proxy.$route.query.id,status:status,userId:state.user.userId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
						}
					})
				},
				getVoteResult(index){
					//0:同意,1:不同意,2:已阅,3:参与人
					//0:参与人,1已阅，2同意，3不同意
					if(index == '3' || index == '2'){
						index = index-2
					}else if(index == '0'){
						index = index+3
					}else{
						index = index+1
					}
					if(index == '5'){
						proxy.$axios.getProtocolFileByProtocolId({protocolId:proxy.$route.query.id}).then((res)=>{
							if(res.data.errorCode == '00000'){
								let obj = res.data.data
								state.menuItemsXyHis = []
								for(var i=0;i<obj.length;i++){
									obj[i].name = obj[i].createTime+'历史版本（'+(obj.length-i)+'）'
									obj[i].color = '#46B1EA'
									state.menuItemsXyHis.push(obj[i])
								}
								state.showXyHis = true
							}
						})
					}else if(index == '3'){
						proxy.$axios.getParticipantAndAdminByProjectId({projectDetailsId:state.detail.projectDetailsId}).then((res)=>{
							if(res.data.data.length){
								state.userList = res.data.data
								console.log(state.userList)
								state.showTP = true
							}else{
								Toast.warn('无人员参与')
							}
						})
					}else{
						proxy.$axios.getVoteResult({protocolFileId:state.detail.protocolFileId,voteResult:index+''}).then((res)=>{
							if(res.data.errorCode == '00000'){
								if(res.data.data.length){
									state.userList = res.data.data
									console.log(state.userList)
									state.showTP = true
								}else{
									Toast.warn('无人员参与')
								}
							}
						})
					}
				},
				showSheet(){
					console.log(showXy)
					showXy.value = true;
				},
				choose(item){
					loading('加载中...')
					event.getOssStsConfig(function(){
						if(item.name=="在线预览本协议"){
							event.downOrViewProtocolFile(state.detail.protocolFileId,0)
							event.getDetail()
						}else{
							event.downOrViewProtocolFile(state.detail.protocolFileId,1)
							event.getDetail()
						}
					})
					
				},
				xyHisClick(item){
					proxy.$router.push({name:'detailXyHis',query:{protocolName:state.detail.protocolName,detail:JSON.stringify(item),title:state.title,img:state.img,userName:state.userName}})
				},
				getOssStsConfig(fun){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.oss = res.data.data
							fun()
						}
					})
				},
				downOrViewProtocolFile(id,type){
					proxy.$axios.downOrViewProtocolFile({accessKeyId:state.oss.accessKeyId,accessKeySecret:state.oss.accessKeySecret,securityToken:state.oss.securityToken,operationType:type,protocolFileId:id}).then(res=>{
						if(res.data.errorCode == '00000'){
							if(type == 0){
								state.fileBox = true
								state.fileUrl = res.data.data.urlLink
								state.fileType = res.data.data.protocolFileType
							}else{
								const link = document.createElement('a');
								link.href = res.data.data.urlLink;
								link.setAttribute('download', res.data.data.protocolFileName);
								link.click();
							}
						}
					})
				},
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
					state.fileType = res.fileType
				},
				getVoteResultCount(){
					proxy.$axios.getVoteResultCount({protocolFileId:state.detail.protocolFileId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							//0:同意,1:不同意,2:已阅,3:所有参与人
							let obj = res.data.data
							for(var i =0;i<obj.length;i++){
								state.xyList[obj[i].voteResult] = obj[i].count
							}
							console.log(state.xyList)
						}
					})
				},
				getProtocolRecordByProtocolId(){
					proxy.$axios.getProtocolRecordByProtocolId({protocolId:proxy.$route.query.id}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.recordList = res.data.data
						}
					})
				},
				getDetail(){
					proxy.$axios.getProtocolByProtocolId({protocolId:proxy.$route.query.id}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.detail = res.data.data
							if(state.detail.protocolFileId){
								event.getVoteResultCount()
								event.getLastHandlingOpinions()
							}
							event.getProtocolRecordByProtocolId()
						}
					})
				},
				loadMore(done){
					state.cur = state.cur+1
					event.getDtList(state.cur,done)
				},
				refresh(done){
					state.cur = 1
					event.getDtList(state.cur,done)
				},
				back(){
					proxy.$router.go(-1)
				}
			}
			return {
				state,
				event,
				uploadFile
			};
		}
	}
</script>

<style>
	.detailXy{
		background-color: #f2f2f2;
		min-height: 100vh;
	}
	.xy_content{
		display: flex;
		justify-content: start;
		flex-direction: column;
		height: calc(100vh - 48px);
	}
	.xy_top{
		margin-top: 10px;
		background-color: #fff;
		font-size: 14px;
		color: #666;
		padding: 10px;
		text-align: left;
	}
	.xy_user{
		margin-top: 20px;
	}
	.xy_user span:last-child{
		color: #999;
		margin-left: 10px;
	}
	.xy_user span:first-child{
		width: 40px;
		height: 40px;
		border-radius: 20px;
		margin-right: 10px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		display: inline-block;
		vertical-align: middle;
	}
	.xy_btn{
		background-color: #F58068;
		padding: 6px 10px;
		border-radius: 4px;
		color: #fff;
		font-size: 12px;
	}
	.xy_banben{
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 10px;
		margin-top: 10px;
		background-color: #fff;
		font-size: 14px;
		color: #999;
	}
	.xy_banben>div div:last-child{
		color: #666;
		margin-top: 10px;
	}
	.xy_banbenZ{
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		color: #666;
		padding: 2px 10px;
	}
	.xy_list{
		background-color: #fff;
	}
	.xy_btns{
		display: flex;
		justify-content: space-around;
		padding: 10px 0;
	}
	.xy_btns>div{
		background-color: #46B1EA;
		color: #fff;
		font-size: 12px;
		border-radius: 4px;
		padding: 6px 10px;
	}
	.xy_bottom{
		overflow-y: scroll;
		padding: 0 20px;
		background-color: #fff;
		margin-top: 10px;
	}
	.record{
		display: flex;
		margin: 10px auto;
	}
	.record_l{
		width: 30px;
		height: 30px;
		border-radius: 15px;
		margin-right: 10px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	.record_r_t{
		display: flex;
		justify-content: space-between;
		color: #666;
		margin-bottom: 10px;
		font-size: 14px;
	}
	.record_r_t>div:last-child{
		font-size: 12px;
		color: #999;
	}
	.xy_tit{
		font-size: 16px;
		color: #333;
		text-align: left;
		font-weight: 400;
		word-wrap: break-word;
	}
	.xy_txt{
		text-align: left;
		font-size: 14px;
		color: #666;
		margin-top: 10px;
	}
	.record_r{
		/* flex: 1;*/
		width: calc(100% - 30px);
		overflow: hidden;
	}
	.record_r>div:last-child{
		background-color: #f2f2f2;
		padding: 5px;
	}
	.userListBox{
		max-height: 70vh;
		width: 80vw;
		border-radius: 10px;
		margin: 0 auto;
		overflow-y: scroll;
	}
	.userList{
		border-bottom: 1px solid #f2f2f2;
		height: 50px;
		line-height: 50px;
		text-align: left;
		display: flex;
		align-items: center;
	}
	.userList:last-child{
		border-bottom: none;
	}
	.userA{
		display: inline-block;
		width: 30px;
		height: 30px;
		border-radius: 15px;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 10px;
		margin-left: 30px;
	}
	.yj_btn{
		display: inline-block;
		background-color: #5382E7;
		color: #fff;
		font-size: 12px;
		height: 30px;
		line-height: 30px;
		width: 70px;
		text-align: center;
		border-radius: 15px;
		margin: 0 10px;
	}
	.tp_v .nut-radiogroup{
		padding-left: 20px;
	}
	.tp_v .nut-radio__icon{
		color: #5382E7;
	}
	.tp_v .nut-radio__icon--unchecked {
	    color: #5382E7;
	}
	.tp_v .nut-textarea{
		background-color: #f2f2f2;
		border-radius: 10px;
		margin: 5px 0 10px;
	}
</style>