<template>
  <div class="file">
		<div class="tab" style="background-color: #5484ea;overflow: hidden;padding-top: env(safe-area-inset-top);">
			<div class="item_top" style="padding-bottom: 10px;">
				<div class="">
					<div @click="event.sumClick">
						{{state.jindu}}<span class="xiala_icon"></span>
					</div>
				</div>
				<div class="top_right">
					<input type="text" v-model="state.inputbValue" class="input_box" @change="event.serach" placeholder="输入资料名/项目名搜索"> 
					<img style="vertical-align: middle;" class="serach_icon" src="../../assets/imgs/icon_home_search.png" alt="">
				</div>
			</div>
		</div>
		<div class="item_cont" id="item_cont">
			<nut-infiniteloading
				  load-txt="加载中..."
				  pull-txt="   "
				  load-more-txt="没有啦～"
				  container-id="item_cont"
				  :use-window="false"
				  :is-open-refresh="true"
				  :has-more="state.customHasMore"
				  load-icon="loading1"
				  pull-icon="loading1"
				  @load-more="customLoadMore"
				  @refresh="refresh"
				>
				<div class="file_li" v-for="item in state.list" @click="event.detail(item)">
					<div class="file_icon_w"></div>
					<div class="file_cont">
						<div class="file_name">{{item.projectDetails.projectName}}</div>
						<div class="file_time">{{item.projectDetails.projectApprovalTime}}</div>
					</div>
				</div>
			</nut-infiniteloading>
		</div>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.showPop">
			<div class="popup_li" v-for="(item,index) in state.porSum" :key="item.code" @click="event.popClick(index,item)">
				{{item.desc}}({{item.number}})
			</div>
		</nut-popup>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
	export default {
		setup() {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				tab1value: '0',
				user: {},
				customHasMore: true,
				showPop: false,
				jindu: '全部进度',
				jinduIndex: 0,
				jinduCode:0,
				cur:1,
				list:[],
				porSum:[],
				inputbValue:''
				
			});
			const customLoadMore = done => {
				state.cur++
				event.getList(state.cur,10,state.jinduCode,8,done)
			}
			const refresh = done => {
				state.cur = 1
				event.getList(state.cur,10,state.jinduCode,8,done)
			}
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				console.log(state.user)
				if(state.user){
					event.getList(1,10,state.jinduCode,8,function(){})
				}else{
					proxy.$router.push('/login')
				}
			})
			const event={
				getSum(projectProgress,type){
					proxy.$axios.projectSummary({projectProgress:projectProgress,type:type,userId:state.user.userId,userRole:state.user.role}).then(res =>{
						if(res.data.errorCode == '00000'){
							state.porSum = res.data.data
							state.jindu = state.porSum[state.jinduIndex].desc+'('+state.porSum[state.jinduIndex].number+')'
						}
					})
				},
				sumClick(){
					state.showPop = true
				},
				popClick(index,item){
					state.showPop = false
					state.jinduIndex = index
					state.jinduCode = item.code
					this.getList(1,10,item.code,8,function(){})
				},
				getList(cur,size,projectProgress,type,fun){
					proxy.$axios.projectListQueryPage({currentPage:cur,pageSize:size,projectProgress:projectProgress,type:type,userId:state.user.userId,userRole:state.user.role,projectName:state.inputbValue}).then(res =>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data
							if(cur < res.data.data.pages){
								state.customHasMore = true
							}else{
								state.customHasMore = false
							}
							if(cur == 1){
								state.list = obj.list
							}else{
								state.list = state.list.concat(obj.list)
							}
							this.getSum(0,state.tab1value)
							fun()
						}
					})
				},
				detail(item){
					proxy.$router.push({name:'fileDetail',query:{id:item.projectDetails.projectDetailsId,name:item.projectDetails.projectName}})
				},
				serach(){
					event.getList(1,10,state.jinduCode,8,function(){})
				}
			}
			return { state,event,toRefs,customLoadMore,refresh };
		}
	};
</script>

<style>
	.item_cont{
		height: calc(100vh - 150px);
		height: calc(var(--vh, 1vh) * 100 - 150px);
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		
	}
	.input_box{
		border: 1px solid #fff;
		background-color: transparent;
		border-radius: 10px;
		height: 20px;
		line-height: 20px;
		padding: 5px 10px;
		font-size: 14px;
		outline: none;
		color: #fff;
	}
	.input_box::placeholder{
		color: #eee;
	}
	.file{
		background-color: #eee;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		padding-bottom: env(safe-area-inset-top);
	}
	.file_li{
		display: flex;
		padding: 10px 10px;
		background-color: #fff;
		border-radius: 10px;
		align-items: center;
	}
	.file_icon_w{
		width: 36px;
		height: 30px;
		background-image: url('../../assets/imgs/file.png');
		background-size: 100%;
		background-repeat: no-repeat;
		margin-right: 20px;
	}
	.file_cont{
		text-align: left;
	}
	.file_name{
		font-size: 15px;
		margin-bottom: 5px;
		word-wrap: break-word;
	}
	.file_time{
		font-size: 12px;
		color: #a1a1a1;
	}
</style>