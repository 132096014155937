<template>
  <div class="">
		<div class="file_li" v-for="item in state.listObj" @click="event.detail(item.informationId || item.protocolFileId,item.fileType || item.protocolFileType,item)">
			<span :class="{'file_icon':true,
							'word_icon':['txt','doc','docx','log'].indexOf(item.fileType || item.protocolFileType) != -1,
							'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(item.fileType || item.protocolFileType) != -1,
							'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(item.fileType || item.protocolFileType) != -1,
							'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(item.fileType || item.protocolFileType) != -1,
							'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(item.fileType || item.protocolFileType) != -1,
							'pdf_icon':['pdf'].indexOf(item.fileType || item.protocolFileType) != -1
						}"></span>
			<div class="file_cont">
				<div class="file_name huanhang">{{item.fileName || item.protocolFileName}}</div>
				<div class="file_time">{{item.updateTime}}</div>
			</div>
		</div>
  </div>
</template>
<script>
	import { reactive, onMounted, getCurrentInstance, onUpdated, onBeforeUnmount, toRefs,ref } from 'vue'
	import { Toast } from '@nutui/nutui';
	export default{
		props:{
			listData: Array
		},
		setup(props,context){
			const { proxy } = getCurrentInstance()
			const state = reactive({
				listObj: {},
				id: '',
				user: [],
				oss: {}
			})
			const loading = (msg)=>{
				Toast.loading(msg)
			} 
			onMounted(()=>{
				state.listObj = props.listData
				state.user = JSON.parse(localStorage.getItem('user'))
			})
			onUpdated(()=>{
				state.listObj = props.listData
			})
			const event = {
				detail(id,type,item){
					state.id = id
					loading('下载中...')
					event.getOssStsConfig(function(){
						if(item.protocolFileId){
							event.downOrViewProtocolFile(id,type)
						}else if(item.fileUploadAddress){
							event.getUrlByUploadAddress(item.fileUploadAddress,type)
						}else{
							event.getFilePreviewUrl(id,type)
						}
					})
				},
				getOssStsConfig(fun){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.oss = res.data.data
							fun()
						}
					})
				},
				getFilePreviewUrl(id,type){
					proxy.$axios.getFilePreviewUrl({accessKeyId:state.oss.accessKeyId,accessKeySecret:state.oss.accessKeySecret,securityToken:state.oss.securityToken,informationId:id}).then(res=>{
						if(res.data.errorCode == '00000'){
							context.emit('fileStatus',{fileBox: true,fileUrl:res.data.data,fileType:type})
						}
					})
				},
				downOrViewProtocolFile(id,type){
					proxy.$axios.downOrViewProtocolFile({accessKeyId:state.oss.accessKeyId,accessKeySecret:state.oss.accessKeySecret,securityToken:state.oss.securityToken,operationType:0,protocolFileId:id}).then(res=>{
						if(res.data.errorCode == '00000'){
							context.emit('fileStatus',{fileBox: true,fileUrl:res.data.data.urlLink,fileType:type})
						}
					})
				},
				getUrlByUploadAddress(id,type){
					proxy.$axios.getUrlByUploadAddress({accessKeyId:state.oss.accessKeyId,accessKeySecret:state.oss.accessKeySecret,securityToken:state.oss.securityToken,operationType:0,fileUploadAddress:id,fileType:type}).then(res=>{
						if(res.data.errorCode == '00000'){
							context.emit('fileStatus',{fileBox: true,fileUrl:res.data.data,fileType:type})
						}
					})
				},
				setAclPrivateByInfoId(id){
					proxy.$axios.setAclPrivateByInfoId(id).then(res=>{
						if(res.data.errorCode == '00000'){
							
						}
					})
				}
			}
			return {
				state,
				event
			}
		},
		
	}
</script>

<style>
	.file_cont{
		/* flex: 1; */
		width: calc(100% - 30px);
	}
	.proUrl{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
</style>