<template>
	<div class="user area-inset-top" style="position: relative;">
		<div class="userInfo">
			<div class="user_tou">
				<span>头像</span>
				<p class="tou_icon">
					<input type="file" @change="event.upload" accept="image/png, image/jpeg, image/jpg" style="opacity: 0;">
					<img :src="state.user.avatarPath" alt="" v-if="state.user.avatarPath" @click="event.upload">
					<span v-if="!state.user.avatarPath">{{(state.user.nickName).slice(state.user.nickName.length-2,state.user.nickName.length)}}</span>
				</p>
			</div>
			<div class="user_name">
				<span>姓名</span>
				<div @click="event.editName">
					{{state.user.nickName}}
					<span class="jian_icon"></span>
				</div>
			</div>
		</div>
		<div class="exit_btn" @click="event.exit">退出登录</div>
		<a style="padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);" class="icp_a" href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2023012749号</a>
		<canvas id="mycanvas" width='132' height='132' class="my-canvas"></canvas>
		<nut-dialog
			teleport=".user"
			title="修改昵称"
			v-model:visible="state.visible"
			customClass="dialog_reset"
			:onOk="event.ok"
		>
		<input type="text" class="input_name" placeholder="请输入昵称" v-model="state.username">
		</nut-dialog>
	</div>
</template>

<script>
	import { reactive, onBeforeMount, onMounted, getCurrentInstance,createVNode, ref, nextTick } from 'vue';
	import { Dialog, Toast } from '@nutui/nutui';
	import oss from 'ali-oss'
	export default {
		setup() {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				user: {},
				username: '',
				visible: false,
				uploadUrl: '',
				headers: '',
				uploadData: '',
				oss: ''
			})
			const event = {
				editName(){
					state.visible = true;
				},
				handler($parent){
					console.log($parent)
				},
				ok(parent){
					proxy.$axios.updateUser({userId:state.user.userId,nickName:state.username}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.user.nickName = state.username
							localStorage.setItem('user',JSON.stringify(state.user))
							window.location.reload()
						}else{
							Toast.fail(res.data.errorMsg)
						}
					})
				},
				exit(){
					localStorage.removeItem('user')
					localStorage.removeItem('token')
					proxy.$router.push('/login')
				},
				upload(file){
					Toast.loading('上传中...')
					event.getOssStsConfig(file.target.files[0])
				},
				getOssStsConfig(file){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.oss = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: 'wmdvc-public'
							});
							event.putObject(file)
						}
					})
				},
				putObject(data){
					let videoName = 'avatar/'+state.user.userId+'_'+proxy.$common.getDateNow('yyyyMMddhhmmss')+'_'+data.name
					console.log(data.name)
					state.oss.put(
					  videoName,
					  data
					).then(result => {
					  console.log(result)
					  if(result.res.status == 200){
					    console.log(result)
						event.modifyAvatar(result,data)
					  }
					}).then(function (r2) {
					  console.log('get success: %j', r2);
					}).catch(function (err) {
					  console.error('error: %j', err);
					});
				},
				modifyAvatar(data,file){
					proxy.$axios.modifyAvatar({userId:state.user.userId,path:data.name}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							state.user.avatarPath = res.data.data.avatarPath
							localStorage.setItem('user',JSON.stringify(state.user))
							
						}
					})
				},
				setAvatar(){
					var canvas = document.getElementById("mycanvas")
					var ctx = canvas.getContext("2d")
					ctx.fillStyle = '#6c95ea'
					ctx.beginPath()
					ctx.arc(66, 66, 62, 0, 2 * Math.PI)
					ctx.fill();
					ctx.font = "45px sans-serif"
					ctx.shadowOffsetX = 3;
					ctx.shadowBlur = 2;
					ctx.shadowColor = "rgba(0, 0, 0, 0.7)";
					ctx.textAlign = 'center'
					ctx.textBaseline = 'middle'
					ctx.fillStyle = '#ffffff'
					ctx.fillText(state.user.nickName.slice(state.user.nickName.length-2,state.user.nickName.length), 66, 70)
					let imgTextUrl = canvas.toDataURL("image/png")
					const arr = imgTextUrl.split(',')
					const mime = arr[0].match(/:(.*?);/)[0]
					const bytes = atob(arr[1])
					const bytesLength = bytes.length
					const u8arr = new Uint8Array(bytesLength)
					for (let i = 0; i < bytes.length; i++) {
						u8arr[i] = bytes.charCodeAt(i)
					}
					let fileName = 'avatar/'+state.user.userId+'_'+proxy.$common.getDateNow('yyyyMMddhhmmss')+'_avatar.png'
					let file = new File([u8arr], fileName,{ type: mime})
					event.getOssStsConfig(file)
				}
			}
			onBeforeMount(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
			})
			onMounted(()=>{
				if(!state.user.avatarPath){
					event.setAvatar()
				}
			})
			return {
				state,
				event
			};
		}
	}
		
</script>

<style>
	.user{
		background-image: url('../../assets/imgs/user_bg.png');
		background-size: 100% 230px;
		background-position: top left;
		background-color: #f1f4fb;
		background-repeat: no-repeat;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		color: #555;
		font-size: 14px;
		overflow: hidden;
	}
	.userInfo{
		background-color: #fff;
		border-radius: 10px;
		width: 90vw;
		margin: 30px auto 0;
	}
	.userInfo div{
		height: 60px;
		line-height: 60px;
		margin: 0 15px;
		border-bottom: 1px solid #EAEAEA;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.userInfo div:last-child{
		border: none;
	}
	.userInfo div span:first-child{
		
	}
	.tou_icon{
		display: flex;
		justify-content: center;
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 20px;
		background-color: #5382E7;
		color: #fff;
		text-align: center;
		position: relative;
		overflow: hidden;
	}
	.tou_icon img{
		width: 100%;
		height: 100%;
	}
	.tou_icon input{
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
	}
	.jian_icon{
		width: 20px;
		height: 20px;
		background-image: url('../../assets/imgs/back_icon.png');
		background-size: 100%;
		background-repeat: repeat;
		transform: rotate(180deg);
	}
	.exit_btn{
		display: block;
		width: 90vw;
		margin: 50px auto 0;
		background-color: #fff;
		border-radius: ;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-size: 16px;
		color: #333;
		border-radius: 10px;
	}
	.dialog_reset .nut-button--plain.nut-button--primary{
		color: #5382E7;
		background: #fff;
	}
	.dialog_reset .nut-button--primary{
		background: #5382E7;
		border-color: #5382E7;
	}
	.input_name{
		width: 87%;
		padding-left: 3%;
		outline: none;
	}
	.icp_a{
		position: absolute;
		bottom: 70px;
		left: 50%;
		margin-left: -70px;
		text-decoration: none;
		color: rgb(125, 126, 128);
	}
</style>