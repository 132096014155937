<template>
	<div class="detail area-inset-top">
		<nut-navbar @on-click-back="event.back" :title="state.title" style="box-shadow: none;" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		    <template #right>
				<div>
					<span :class="{'guanzhu_icon':true,'on':state.type == 1}" @click="event.guanzhu"></span>
					<span class="people_icon" @click="event.people"></span>
				</div>
		    </template>
		</nut-navbar>
		<div class="zanfan_top" v-if="state.step == 7"><span></span>项目暂缓</div>
		<div class="touhou_top" v-if="state.step == 6"><span></span>投后</div>
		<div class="item_tab_span" style="text-align: left;overflow-x: scroll;white-space: nowrap;">
			<span style="margin-bottom:5px;" v-for="item in state.detail.projectLabelDtoList">{{item.labelName}}</span>
		</div>
		<div class="step" v-if="['1','2','3','4','5','8', '9','10'].indexOf(state.step) !== -1">
			<div class="step_item" v-if="['1','2','9','10'].indexOf(state.step) !== -1" v-for="(item,index) in state.stepList" :class="{on:item.code == state.step}">
				<div class="step_label">{{item.title}}</div>
				<div class="step_icon"></div>
				<div class="line"></div>
			</div>
			<div class="step_item" v-if="['3','4','5','8'].indexOf(state.step) !== -1" v-for="(item,index) in state.stepList1" :class="{on:item.code == state.step}">
				<div class="step_label">{{item.title}}</div>
				<div class="step_icon"></div>
				<div class="line"></div>
			</div>
		</div>
		<!-- 新增投票 -->
		<div v-if="state.fqState == 0 && state.guanliState">
			<div class="next_btn1" @click="event.insert">是否发起投票？确认发起投票请点击</div>
		</div>
		<div style="position: relative;" v-if="state.detail.projectDetails">
			<div v-show="!state.guanliState && state.fqState == 0" style="height: 50px;display: flex;align-items: center;justify-content: center;z-index: 1;color:red;"><!-- <span style='background-color: #5484EA;color:#fff;border-radius: 20px;height:20px;line-height: 20px;padding:5px 10px;font-size: 14px;margin-bottom: 10px;'> -->本项目的负责人尚未发起投票<!-- </span> --></div>
			<div :class="{'tp_over1':true,'mosaic':!state.guanliState && state.fqState == 0}" v-show="state.fqState == 2">
				<div class="tp_over_tit">投票结果</div>
				<div :class="{'tp_box1':true,'vs1':state.VoteDataOpt1 > state.VoteDataOpt2,'vs2':state.VoteDataOpt1 < state.VoteDataOpt2,'vs3':state.VoteDataOpt1 == state.VoteDataOpt2}">
					<div @click="event.voteDetail"><span>{{state.VoteDataOpt1}}</span><br><span	v-if="state.detail.projectDetails.projectProgress == '9'">同意立项</span><span v-else>投资</span></div>
					<div></div>
					<div @click="event.voteDetail"><span>{{state.VoteDataOpt2}}</span><br>暂缓</div>
				</div>
			</div>
		</div>
		<div class="tp1" v-show="state.fqState == 1">
			<div class="tp_tit">投票时间还剩</div>
			<div class="tp_time">
				<nut-countdown v-model="state.resetTime" :endTime="state.end">
					<div class="countdown-part-box">
						<div class="part-item">{{ state.resetTime.d }}</div>
						<span class="part-item-symbol">天</span>
						<div class="part-item">{{ state.resetTime.h }}</div>
						<span class="part-item-symbol">时</span>
						<div class="part-item">{{ state.resetTime.m }}</div>
						<span class="part-item-symbol">分</span>
						<div class="part-item">{{ state.resetTime.s }}</div>
						<span class="part-item-symbol">秒</span>
					</div>
				</nut-countdown>
			</div>
			<div class="tp_cont" v-if="state.detail.projectDetails">
				<div :class="{'tp_obj1':true,'on':state.VoteDataUser.voteType == '1' }" @click="event.voteDetail">
					<div class="tp_num chen_bg">{{state.VoteData1.length}}</div>
					<div class="tp_obj_cont">
						<div class="tp_t">
							<nut-avatar-group zIndex="right" span="-16" max-count="4" max-color="#fff" max-bgColor="#6692EF">
								<nut-avatar size="small" v-for="item in state.VoteData1" :url="item.avatarPath">
								</nut-avatar>
							</nut-avatar-group>
						</div>
						<div class="tp_btn chen_bg" @click.stop="event.tpClick('1')"><span v-if="state.detail.projectDetails.projectProgress == '9'">同意立项</span><span v-else>投资</span></div>
					</div>
				</div>
				<span class="jt_icon"></span>
				<div :class="{'tp_obj2':true,'on':state.VoteDataUser.voteType == '2' }" @click="event.voteDetail">
					<div class="tp_num longBlue_bg">{{state.VoteData2.length}}</div>
					<div class="tp_obj_cont">
						<div class="tp_t">
							<nut-avatar-group zIndex="right" span="-16" max-count="4" max-color="#fff" max-bgColor="#6692EF">
								<nut-avatar size="small" v-for="item in state.VoteData2" :url="item.avatarPath">
								</nut-avatar>
							</nut-avatar-group>
						</div>
						<div class="tp_btn longBlue_bg" @click.stop="event.tpClick('2')">暂缓</div>
					</div>
				</div>
			</div>
			<div class="tp_tip">注：投票有效期内可随时更改您的投票意向</div>
		</div>
		
		
		<div class="tab" ref="myElement">
			<!-- sticky :top="state.topV" -->
			<nut-tabs v-model="state.tab1value" title-gutter="10" background="#f7f7f7" color="#919191">
				<template v-slot:titles>
					<div class="nut-tabs__titles-item" @click="event.tabClick(item)" :class="{active:state.tab1value==item.paneKey}" :key="item.paneKey"  v-for="item in state.list4">
						<span class="nut-tabs__titles-item__text">{{item.title}}</span>
						<span class="nut-tabs__titles-item__line"></span>
					</div>
				</template>
				<nut-tabpane>
					<template v-slot:default v-if="state.tab1value == 0">
						<div class="info" :style="'height:calc(100vh - '+state.height+'px);margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'">
							<div class="info_top">
								<div class="address">
									<span class="address_icon"></span>
									<div v-if="state.detail.projectDetails">
										<p>{{state.detail.projectDetails.regionId}}</p>
										<p>{{state.detail.projectDetails.address}}</p>
									</div>
								</div>
								<div class="tel">
									<span class="tel_icon"></span>
									<div v-if="state.detail.projectDetails">{{state.detail.projectDetails.telephone}}</div>
								</div>
							</div>
							<div class="info_bottom">
								<div class="info_tit"><span class="line_icon"></span>简介</div>
								<div v-if="state.detail.projectDetails">{{state.detail.projectDetails.introduction}}</div>
								<div class="info_tit"><span class="line_icon"></span>备注</div>
								<div v-if="state.detail.projectDetails" v-html="state.detail.projectDetails.remarks"></div>
							</div>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 1">
						<div class="fw" :style="'height:calc(100vh - '+state.height+'px);margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'">
							<div class="cw">
								<div class="laberItem">
									<span>融资需求</span>
									<div v-if="state.detail.projectCapitalizeInfoList[0].financingDemand<9999">
										<nut-price :price="state.detail.projectCapitalizeInfoList[0].financingDemand" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectCapitalizeInfoList[0].financingDemand && state.detail.projectCapitalizeInfoList[0].financingDemand.toString().split('.')[1]) ? state.detail.projectCapitalizeInfoList[0].financingDemand.toString().split('.')[1].length:0" />万元
									</div>
									<div v-if="state.detail.projectCapitalizeInfoList[0].financingDemand>9999">
										<nut-price :price="(state.detail.projectCapitalizeInfoList[0].financingDemand/10000).toFixed((state.detail.projectCapitalizeInfoList[0].financingDemand.toString().split('.')[1]?state.detail.projectCapitalizeInfoList[0].financingDemand.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectCapitalizeInfoList[0].financingDemand/10000).toString().split('.')[1] ? (state.detail.projectCapitalizeInfoList[0].financingDemand.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
								<div class="laberItem">
									<span>项目估值</span>
									<div v-if="state.detail.projectCapitalizeInfoList[0].projectValuation<9999">
										<nut-price :price="state.detail.projectCapitalizeInfoList[0].projectValuation" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectCapitalizeInfoList[0].projectValuation && state.detail.projectCapitalizeInfoList[0].projectValuation.toString().split('.')[1]) ? state.detail.projectCapitalizeInfoList[0].projectValuation.toString().split('.')[1].length:0" />万元
									</div>
									<div v-if="state.detail.projectCapitalizeInfoList[0].projectValuation>9999">
										<nut-price :price="(state.detail.projectCapitalizeInfoList[0].projectValuation/10000).toFixed((state.detail.projectCapitalizeInfoList[0].projectValuation.toString().split('.')[1]?state.detail.projectCapitalizeInfoList[0].projectValuation.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectCapitalizeInfoList[0].projectValuation/10000).toString().split('.')[1] ? (state.detail.projectCapitalizeInfoList[0].projectValuation.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
								<div class="laberItem">
									<span>拟投资金额</span>
									<div v-if="state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount<9999">
										<nut-price :price="state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount && state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount.toString().split('.')[1]) ? state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount.toString().split('.')[1].length:0" />万元
									</div>
									<div v-if="state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount>9999">
										<nut-price :price="(state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount/10000).toFixed((state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount.toString().split('.')[1]?state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount/10000).toString().split('.')[1] ? (state.detail.projectCapitalizeInfoList[0].proposedInvestmentAmount.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
								<div class="laberItem" style="border: none;">
									<span>占股比例</span><div><nut-price :price="state.detail.projectCapitalizeInfoList[0].proportion" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectCapitalizeInfoList[0].proportion && state.detail.projectCapitalizeInfoList[0].proportion.toString().split('.')[1]) ? state.detail.projectCapitalizeInfoList[0].proportion.toString().split('.')[1].length:0" />%</div>
								</div>
								<div class="labelTit"><span class="line_icon"></span>简要财务</div>
								<div class="laberItem">
									<span>总资产</span>
									<div v-if="state.detail.projectFinanceInfoList[0].totalAssets<9999">
										<nut-price :price="state.detail.projectFinanceInfoList[0].totalAssets" symbol=" " size="normal" :thousands="true"   :decimal-digits="(state.detail.projectFinanceInfoList[0].totalAssets && state.detail.projectFinanceInfoList[0].totalAssets.toString().split('.')[1]) ? state.detail.projectFinanceInfoList[0].totalAssets.toString().split('.')[1].length:0" />万元
									</div>
									<div v-if="state.detail.projectFinanceInfoList[0].totalAssets>9999">
										<nut-price :price="(state.detail.projectFinanceInfoList[0].totalAssets/10000).toFixed((state.detail.projectFinanceInfoList[0].totalAssets.toString().split('.')[1]?state.detail.projectFinanceInfoList[0].totalAssets.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectFinanceInfoList[0].totalAssets/10000).toString().split('.')[1] ? (state.detail.projectFinanceInfoList[0].totalAssets.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
								<div class="laberItem">
									<span>净资产</span>
									<div v-if="state.detail.projectFinanceInfoList[0].netAssets<9999">
										<nut-price :price="state.detail.projectFinanceInfoList[0].netAssets" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectFinanceInfoList[0].netAssets && state.detail.projectFinanceInfoList[0].netAssets.toString().split('.')[1]) ? state.detail.projectFinanceInfoList[0].netAssets.toString().split('.')[1].length:0" />万元
									</div>
									<div v-if="state.detail.projectFinanceInfoList[0].netAssets>9999">
										<nut-price :price="(state.detail.projectFinanceInfoList[0].netAssets/10000).toFixed((state.detail.projectFinanceInfoList[0].netAssets.toString().split('.')[1]?state.detail.projectFinanceInfoList[0].netAssets.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true"   :decimal-digits="(state.detail.projectFinanceInfoList[0].netAssets/10000).toString().split('.')[1] ? (state.detail.projectFinanceInfoList[0].netAssets.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
								<div class="laberItem">
									<span>营业收入</span>
									<div v-if="state.detail.projectFinanceInfoList[0].operatingRevenue<9999">
										<nut-price :price="state.detail.projectFinanceInfoList[0].operatingRevenue" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectFinanceInfoList[0].operatingRevenue && state.detail.projectFinanceInfoList[0].operatingRevenue.toString().split('.')[1]) ? state.detail.projectFinanceInfoList[0].operatingRevenue.toString().split('.')[1].length:0"  />万元
									</div>
									<div v-if="state.detail.projectFinanceInfoList[0].operatingRevenue>9999">
										<nut-price :price="(state.detail.projectFinanceInfoList[0].operatingRevenue/10000).toFixed((state.detail.projectFinanceInfoList[0].operatingRevenue.toString().split('.')[1]?state.detail.projectFinanceInfoList[0].operatingRevenue.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectFinanceInfoList[0].operatingRevenue/10000).toString().split('.')[1] ? (state.detail.projectFinanceInfoList[0].operatingRevenue.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
								<div class="laberItem">
									<span>毛利润</span>
									<div v-if="state.detail.projectFinanceInfoList[0].grossProfit<9999">
										<nut-price :price="state.detail.projectFinanceInfoList[0].grossProfit" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectFinanceInfoList[0].grossProfit && state.detail.projectFinanceInfoList[0].grossProfit.toString().split('.')[1]) ? state.detail.projectFinanceInfoList[0].grossProfit.toString().split('.')[1].length:0" />万元
									</div>
									<div v-if="state.detail.projectFinanceInfoList[0].grossProfit>9999">
										<nut-price :price="(state.detail.projectFinanceInfoList[0].grossProfit/10000).toFixed((state.detail.projectFinanceInfoList[0].grossProfit.toString().split('.')[1]?state.detail.projectFinanceInfoList[0].grossProfit.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true"  :decimal-digits="(state.detail.projectFinanceInfoList[0].grossProfit/10000).toString().split('.')[1] ? (state.detail.projectFinanceInfoList[0].grossProfit.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
								<div class="laberItem" style="border: none;">
									<span>净利润</span>
									<div v-if="state.detail.projectFinanceInfoList[0].netProfit<9999">
										<nut-price :price="state.detail.projectFinanceInfoList[0].netProfit" symbol=" " size="normal" :thousands="true" :decimal-digits="(state.detail.projectFinanceInfoList[0].netProfit && state.detail.projectFinanceInfoList[0].netProfit.toString().split('.')[1]) ? state.detail.projectFinanceInfoList[0].netProfit.toString().split('.')[1].length:0" />万元
									</div>
									<div v-if="state.detail.projectFinanceInfoList[0].netProfit>9999">
										<nut-price :price="(state.detail.projectFinanceInfoList[0].netProfit/10000).toFixed((state.detail.projectFinanceInfoList[0].netProfit.toString().split('.')[1]?state.detail.projectFinanceInfoList[0].netProfit.toString().split('.')[1].length:0)+4)" symbol=" " size="normal" :thousands="true" :decimal-digits="(state.detail.projectFinanceInfoList[0].netProfit/10000).toString().split('.')[1] ? (state.detail.projectFinanceInfoList[0].netProfit.toString().split('.')[1].length+4):0" />亿元
									</div>
								</div>
							</div>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 2">
						<nut-tabs title-gutter="10" background="#f7f7f7" color="#919191">
							<template v-slot:titles>
								<div class="nut-tabs__titles-item" @click="event.tabChildClick1(item)" :class="{active:state.tabChildvalue1==item.paneKey}" :key="item.paneKey"  v-for="item in state.listChild1">
									<span class="nut-tabs__titles-item__text">{{item.title}}</span>
									<span class="nut-tabs__titles-item__line"></span>
								</div>
							</template>
							<nut-tabpane>
								<template v-slot:default>
									<div class="list" :style="'height:calc(100vh - '+state.height+'px);width: 100%;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'" id="scroll1">
										<nut-infiniteloading
										 containerId = 'scroll1'
										 pull-icon="loading1"
										:use-window='false'
										:is-open-refresh="true"
										:has-more="false"
										 @refresh="event.refresh1"
										>
											<zl-component :listData="state.fileData" @fileStatus="event.getStatus"></zl-component>
										</nut-infiniteloading>
										<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
									</div>
								</template>
							</nut-tabpane>
						</nut-tabs>
					</template>
					<template v-slot:default v-if="state.tab1value == 3">
						<nut-tabs title-gutter="10" background="#f7f7f7" color="#919191">
							<template v-slot:titles>
								<div class="nut-tabs__titles-item" @click="event.tabChildClick(item)" :class="{active:state.tabChildvalue==item.paneKey}" :key="item.paneKey"  v-for="item in state.listChild">
									<span class="nut-tabs__titles-item__text">{{item.title}}</span>
									<span class="nut-tabs__titles-item__line"></span>
								</div>
							</template>
							<nut-tabpane style="background-color: #f7f7f7">
								<template v-slot:default>
									<div class="list" v-if="state.fileDataXy.length != 0" :style="'height:calc(100vh - '+state.height+'px - 50px);width: 94%;margin: 0 auto;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'" id="scroll">
										<nut-infiniteloading
										 containerId = 'scroll'
										 load-icon="loading1"
										 pull-icon="loading1"
										:use-window='false'
										:has-more="state.hasMore"
										:is-open-refresh="true"
										 @load-more="event.loadMore"
										 @refresh="event.refresh"
										>
											<xy-component :listData="state.fileDataXy" :title="state.title" @fileStatus="event.getStatus"></xy-component>
										</nut-infiniteloading>
										<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
									</div>
									<div v-if="state.fileDataXy.length == 0" class="middle" :style="'height:calc(100vh - '+state.height+'px - 50px);'">
										<img src="../../assets/imgs/empt.png" alt="" style="margin-bottom: 30px;">
										<div>空空如也</div>
									</div>
								</template>
							</nut-tabpane>
						</nut-tabs>
					</template>
					<template v-slot:default v-if="state.tab1value == 4">
						<div class="list" :style="'height:calc(100vh - '+state.height+'px);width: 100%;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'" id="scroll">
							<nut-infiniteloading
							 containerId = 'scroll'
							 load-icon="loading1"
							 pull-icon="loading1"
							:use-window='false'
							:has-more="state.hasMore"
							:is-open-refresh="true"
							 @load-more="event.loadMore"
							 @refresh="event.refresh"
							>
								<dt-component :listData="state.listData"></dt-component>
							</nut-infiniteloading>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 5">
						<div class="list" :style="'height:calc(100vh - '+state.height+'px);width: 100%;background:#f2f2f2;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'" id="scroll">
							<nut-infiniteloading
							 containerId = 'scroll'
							 load-icon="loading1"
							 pull-icon="loading1"
							:use-window='false'
							:has-more="state.hasMore"
							:is-open-refresh="true"
							 @load-more="event.loadMore"
							 @refresh="event.refresh"
							>
								<jg-component :listData="state.listJG"></jg-component>
							</nut-infiniteloading>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
					</template>
				</nut-tabpane>
			</nut-tabs>
		</div>
		
		<div class="edit_icon" @click="event.edit"></div>
		<div class="bottom">
			<div>
				<input type="text" v-model="state.inputValue" class="pl_input" placeholder="评论或记笔记,会发布在动态里">
				<span class="tj_btn" @click="event.submit">提交</span>
			</div>
		</div>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.showPop">
			<div class="popup_li" v-for="(item,index) in state.edit" :key="index" @click="event.popClick(index,item)">
				{{item}}
			</div>
		</nut-popup>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.showJD">
			<div class="popup_li" v-for="item in state.JDList" :key="item.value" @click="event.JDClick(item)">
				{{item.label}}
			</div>
		</nut-popup>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.showFL">
			<div class="popup_li" v-for="item in state.FLList" :key="item.value" @click="event.FLClick(item)">
				{{item.label}}
			</div>
		</nut-popup>
		<nut-dialog
			teleport=".user"
			title="请输入项目名称"
			v-model:visible="state.showMC"
			customClass="dialog_reset"
			:onOk="event.okMC"
		>
			<input type="text" class="input_label" v-model="state.itemName">
		</nut-dialog>
		<nut-popup pop-class="pop_cont" round position="bottom" style="width: 100%;" v-model:visible="state.showJJ">
			<nut-textarea max-length="300" v-model="state.itemJJ" limit-show autosize placeholder="请输入简介" autofocus/>
			<span class="tj_btn" style="margin-bottom: 10px;" @click="event.submitJJ">提交</span>
		</nut-popup>
		<input type="file" ref="uploadFile" @change="event.fileChange" style="width:0;height:0;opacity: 0;">
		<input type="file" ref="uploadImg" accept="image/*" style="width:0;height:0;opacity: 0;"  @change="event.fileChange">
		<input type="file" ref="uploadXy" style="width:0;height:0;opacity: 0;"  @change="event.fileChange">
		<nut-address
			v-model="itemDY"
		    v-model:visible="state.showDY"
			close-btn-icon="checklist"
		    :province="state.province"
		    :city="state.city"
		    :country="state.country"
			:town="state.town"
		    @change="event.onChange"
		    @close="event.close"
			@close-mask="event.closeMask"
		    custom-address-title="请选择地区"
		></nut-address>
		<nut-popup pop-class="pop_cont" position="bottom" style="width: 100%;" v-model:visible="state.showTP">
			<div class="tp_p_cont">
				<div class="tpp_tit">投票详情</div>
				<div class="tpp_c_t" v-if="state.detail.projectDetails">
					<div class="tpp_t"><span v-if="state.detail.projectDetails.projectProgress == '9'">同意立项</span><span v-else>投资</span>{{state.VoteData1.length}}</div>
					<div class="tpp_t">暂缓{{state.VoteData2.length}}</div>
				</div>
				<div class="tpp_txt">
					<div class="tpp_c">
						<div class="tpp_c_li" v-for="(item,index) in state.VoteData1">
							<span><img :src="item.avatarPath" alt=""></span>
							<div>
								<div class="tpp_name">{{item.nickName}}</div>
								<div class="tpp_date" v-if="item.voteTime">{{item.voteTime.split(' ')[0]}}</div>
							</div>
						</div>
					</div>
					<div class="tpp_c">
						<div class="tpp_c_li" v-for="(item,index) in state.VoteData2">
							<span><img :src="item.avatarPath" alt=""></span>
							<div>
								<div class="tpp_name">{{item.nickName}}</div>
								<div class="tpp_date" v-if="item.voteTime">{{item.voteTime.split(' ')[0]}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nut-popup>
		<nut-popup pop-class="pop_cont" position="bottom" style="width: 100%;" v-model:visible="state.showXY">
			<div class="tp_p_cont">
				<div class="tp_Item">
					<div class="tp_l">请输入协议名称</div>
					<div class="tp_v"><input class="pl_input" type="text" placeholder="请输入协议名称" style="margin: 0;" v-model="state.protocolName"></div>
				</div>
				<div class="tp_Item">
					<div class="tp_l">(选填)输入协议简介</div>
					<div class="tp_v"><nut-textarea max-length="300" rows="3" v-model="state.protocolIntroduction" limit-show autosize placeholder="请输入协议简介"/></div>
				</div>
				<div class="tp_Item">
					<div class="tp_l">(选填)上传协议文件</div>
					<div class="tp_v"><div class="upload_btn" style="position: relative;bottom: 0;left: 10px;" @click="event.uploadXyEvent"></div></div>
				</div>
				<div class="btn_Item">
					<span class="tj_btn" @click="state.showXY=false">取消</span>
					<span class="tj_btn" @click="event.submitJJXy">提交</span>
				</div>
			</div>
		</nut-popup>
		<file-p v-if="state.fileBox" :url="state.fileUrl" :type="state.fileType" @fileClose="event.getStatus"></file-p>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, ref, computed, onUnmounted, nextTick} from 'vue';
	import {useRouter} from 'vue-router'
	import { Dialog, Toast } from '@nutui/nutui';
	import dtComponent from '@/view/trends/list'
	import zlComponent from '@/view/file/list'
	import xyComponent from '@/view/item/listXy'
	import jgComponent from '@/view/mechanism/list'
	import fileP from '@/view/file/iframe'
	import oss from 'ali-oss'
	import city from '@/components/city2.json'
	export default {
		components:{
			dtComponent,
			zlComponent,
			xyComponent,
			jgComponent,
			fileP
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const router = useRouter()
			const uploadFile = ref(null)
			const uploadImg = ref(null)
			const uploadXy = ref(null)
			const myElement = ref(null)
			const itemDY = ref([])
			const state = reactive ({
				step: 3,
				height: 0,
				topV: 0,
				end: Date.now()+60*60*1000,
				projectId: 0,
				resetTime: {
					d: '1',
					h: '00',
					m: '00',
					s: '00'
				},
				tab1value: 0,
				tabChildvalue: 0,
				tabChildvalue1: 0,
				stepList:[
					{
						title:'新入库',
						code: '10'
					},
					{
						title:'见面',
						code: '1'
					},
					{
						title:'签NDA',
						code: '2'
					},
					{
						title:'立项投票',
						code: '9'
					},
					{
						title:'立项',
						code: '8'
					},
				],
				stepList1:[
					{
						title:'立项',
						code: '8'
					},
					{
						title:'出TS',
						code: '3'
					},
					{
						title:'尽调',
						code: '4'
					},
					{
						title:'投决中',
						code: '5'
					}
				],
				list4: [
					{
						title:'信息',
						paneKey: '0',
					},
					{
						title:'财务',
						paneKey: '1',
					},
					{
						title:'资料',
						paneKey: '2',
					},
					{
						title:'协议',
						paneKey: '3',
					},
					{
						title:'动态',
						paneKey: '4',
					},
					{
						title:'关联机构',
						paneKey: '5',
					}
				],
				listChild: [
					{
						title:'评审中',
						paneKey: '0',
					},
					{
						title:'已定稿',
						paneKey: '1',
					},
					{
						title:'作废',
						paneKey: '2',
					},
				],
				listChild1: [
					{
						title:'资料文件',
						paneKey: '0',
					},
					{
						title:'协议文件',
						paneKey: '1',
					},
					{
						title:'历史协议文件',
						paneKey: '2',
					},
				],
				tpClickNum: 1,
				detail:{projectDetails:{projectProgress:'0'}},
				listData:[],
				fileData:[],
				fileDataXy:[],
				VoteData1:[],
				VoteDataDetail1:[],
				VoteDataDetail2:[],
				VoteData2:[],
				VoteDataOpt1:0,
				VoteDataOpt2:0,
				cur:1,
				haMore: true,
				haMore1: true,
				user:[],
				title:'',
				type: 0,
				inputValue: '',
				showPop: false,
				showJD: false,
				showFL: false,
				showMC: false,
				showJJ: false,
				showDY: false,
				showTP: false,
				showXY: false,
				itemName:'',
				itemJJ:'',
				itemDY: '',
				edit:['进度','项目标签','项目名称','项目分组','地域','简介','备注','财务','新增协议','上传资料：相册','上传资料：文件'],
				JDList:[{label:'见面',id:1},{label:'签NDA',id:2},{label:'立项',id:8},{label:'出TS',id:3},{label:'尽调',id:4},{label:'发起投决',id:5},{label:'暂缓',id:7},{label:'投后',id:6}],
				FLList:[{label:'新项目',id:1},{label:'重点',id:2},{label:'跟进中',id:9},{label:'其他',id:3},{label:'归档',id:4}],
				province: [],
				city: [],
				country: [],
				town:[],
				addressValue:[],
				address: [],
				VoteDataUser: '',
				time: '',
				iosHeight: '44px',
				fileUrl: '',
				fileBox: false,
				fileType: '',
				guanliState: false,
				fqState: -1,
				protocolIntroduction: '',
				protocolName:'',
				uploadXYStatus: false,
				uploadResult:'',
				uploadData: '',
				projectVoteId: '',
				isBack: false,
				listJG:[]
			});
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
				event.getDetail()
				event.getInformation(function(){})
				state.province = city
				state.time = setTimeout(function(){
					state.topV = 45
					state.height = myElement.value.offsetTop+90
				},1000)
				const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
				if (isInStandaloneMode()) {
				}else {
					console.log('浏览器')
					state.iosHeight = '100px'
				}
			})
			onUnmounted(()=>{
				clearTimeout(state.time)
			})
			const event = {
				uploadXyEvent(){
					state.uploadXYStatus = true
					uploadXy.value.click()
				},
				getVoteStatus(step){
					let voteCategorize = 0
					if(step == '9'){
						voteCategorize = 1
					}
					proxy.$axios.getVoteStatus({projectId:state.projectId,userId:state.user.userId,voteCategorize:voteCategorize}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.fqState = res.data.data.voteStatus
							state.guanliState = res.data.data.admin
							state.projectVoteId = res.data.data.projectVoteId
							if(state.fqState == 1){
								event.getVoteMsgById(state.projectId)
								state.end = new Date(res.data.data.endTime).getTime()
							}else if(state.fqState == 2){
								event.getVoteMsgById(state.projectId)
							}
						}
					})
				},
				comTp(){
					Dialog({
						title: '确认发起投票吗',
						customClass:'dialog_reset',
						onCancel:function(){
							
						},
						onOk:function(){
							event.insert()
						}
					});
				},
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
					state.fileType = res.fileType
				},
				selectProjectCategory(){
					proxy.$axios.selectProjectCategory().then((res)=>{
						if(res.data.errorCode == '00000'){
							state.FLList = res.data.data
						}
					})
				},
				selectProjectProgress(){
					proxy.$axios.selectProjectProgress().then((res)=>{
						if(res.data.errorCode == '00000'){
							state.JDList = res.data.data
						}
					})
				},
				getDetail(){
					state.detail = ''
					state.step = ''
					state.fqState = -1
					state.guanliState = false
					proxy.$axios.projectDetailsQuery(proxy.$route.query.id).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.detail = res.data.data
							state.step = state.detail.projectDetails.projectProgress
							state.title = state.detail.projectDetails.projectName
							event.selectFocusOnProjects()  //获取是否关注
							if(['5','6','7','9'].indexOf(state.step) != -1){  //投票
								event.getVoteStatus(state.step)
							}else{
								state.VoteData1 = []
								state.VoteData2 = []
							}
							if(sessionStorage.getItem('pages') == 'file'){
								state.tab1value = 2
								sessionStorage.removeItem('pages')
							}else if(['0','1','2','3'].indexOf(sessionStorage.getItem('pages')) != -1){
								event.tabClick({paneKey:JSON.parse(sessionStorage.getItem('pages'))})
								sessionStorage.removeItem('pages')
							}
						}
					})
				},
				tabClick(item){
					state.tab1value = item.paneKey
					state.height = myElement.value.offsetTop+90
					if(item.paneKey == 4){
						state.cur = 1
						event.getDtList(state.cur,function(){})
					}else if(item.paneKey == 2){
						event.getInformation(function(){})
					}else if(item.paneKey == 3){
						event.getProtocolByProjectId(sessionStorage.getItem('pages1') || 0)
						state.tabChildvalue = sessionStorage.getItem('pages1') || 0
						sessionStorage.removeItem('pages1')
						console.log(state.tabChildvalue)
					}else if(item.paneKey == 5){
						event.getOgzProjectListByPjtId(function(){})
					}
				},
				tabChildClick(item){
					state.tabChildvalue = item.paneKey || 0
					event.getProtocolByProjectId(item.paneKey)
				},
				tabChildClick1(item){
					state.tabChildvalue1 = item.paneKey || 0
					if(item.paneKey == 1){
						event.getProtocolFileByProjectId(item.paneKey-1)
					}else if(item.paneKey == 2){
						event.getProtocolFileByProjectId(item.paneKey-1)
					}else if(item.paneKey == 0){
						event.getInformation(function(){})
					}
				},
				getOgzProjectListByPjtId(){
					proxy.$axios.getOgzProjectListByPjtId({projectId:state.projectId}).then(res =>{
						if(res.data.errorCode == '00000'){
							state.listJG = res.data.data
						}
					})
				},
				getDtList(curpage,fun){
					proxy.$axios.pageList(curpage,10,{projectId:state.projectId}).then(res=>{
						if(res.data.errorCode == '00000'){
							let data = res.data.data.records
							let obj = {}
							for(var i = 0;i<data.length;i++){
								let objId = data[i].createTime.split(' ')[0]
								if(obj[objId]){
									obj[objId].push(data[i])
								}else{
									obj[objId] = []
									obj[objId].push(data[i])
								}
							}
							if(curpage!=1){
								Object.keys(obj).forEach(key=>{
									if(state.listData[key]){
										state.listData[key] = state.listData[key].concat(obj[key])
									}else{
										state.listData[key] = obj[key]
									}
								})
							}else{
								state.listData = obj
							}
							if(curpage < res.data.data.pages){
								state.hasMore = true
							}else{
								state.hasMore = false
							}
							fun()
						}
					})
				},
				getInformation(fun){
					proxy.$axios.getInformation(state.projectId).then(res=>{
						if(res.data.errorCode == '00000'){
							state.fileData = res.data.data
							fun()
						}
					})
				},
				getProtocolByProjectId(status){
					proxy.$axios.getProtocolByProjectId({projectId:state.projectId,status:status}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.fileDataXy = res.data.data
						}
					})
				},
				getProtocolFileByProjectId(status){
					proxy.$axios.getProtocolFileByProjectId({projectId:state.projectId,status:status}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.fileData = res.data.data
						}
					})
				},
				getVoteMsgById(id){
					state.VoteData1 = []
					state.VoteData2 = []
					let voteCategorize = 0
					if(state.detail.projectDetails.projectProgress == '9'){
						voteCategorize = 1
					}
					proxy.$axios.getVoteMsgById({projectId:id,voteCategorize:voteCategorize}).then(res=>{
						if(res.data.errorCode == '00000'){
							if(res.data.data.length){
								event.getVoteMsgByPjtVtIdAndUserId(state.projectId) //获取当前用户投票项
								event.getVoteLastDetailByVoteId(state.projectVoteId)
							}else{
								event.getVoteLastResultById(state.projectId)
								event.getVoteLastDetailByVoteId(state.projectVoteId)
							}
						}
					})
				},
				getVoteLastDetailByVoteId(id){
					if(!id) return
					state.VoteData1 = []
					state.VoteData2 = []
					proxy.$axios.getVoteLastDetailByVoteId({projectVoteId:id}).then(res=>{
						if(res.data.errorCode == '00000'){
							if(res.data.data.length){
								let obj = res.data.data
								obj.forEach(item=>{
									if(item.voteType == 1){
										state.VoteData1 = item.userRespDtoList
									}else if(item.voteType == 2){
										state.VoteData2 = item.userRespDtoList
									}
								})
							}
						}
					})
				},
				getVoteLastResultById(id){
					let voteCategorize = 0
					if(state.detail.projectDetails.projectProgress == '9'){
						voteCategorize = 1
					}
					proxy.$axios.getVoteLastResultById({projectId:id,voteCategorize:voteCategorize}).then(res=>{
						if(res.data.errorCode == '00000'){
							if(res.data.data){
								state.projectVoteId = res.data.data.projectVoteId
								let obj = res.data.data.voteTypeNumDtoList
								obj.forEach(item=>{
									if(item.voteType == 1){
										state.VoteDataOpt1 = item.num
									}else if(item.voteType == 2){
										state.VoteDataOpt2 = item.num
									}
								})
							}
						}
					})
				},
				getVoteMsgByPjtVtIdAndUserId(id){
					let voteCategorize = 0
					if(state.detail.projectDetails.projectProgress == '9'){
						voteCategorize = 1
					}
					proxy.$axios.getVoteMsgByPjtVtIdAndUserId({projectId:id,userId:state.user.userId,voteCategorize:voteCategorize}).then(res=>{
						if(res.data.errorCode == '00000'){
							if(res.data.data != null){
								state.VoteDataUser = res.data.data
							}
						}
					})
				},
				loadMore(done){
					state.cur = state.cur+1
					event.getDtList(state.cur,done)
				},
				refresh(done){
					state.cur = 1
					event.getDtList(state.cur,done)
				},
				refresh1(done){
					event.getInformation(done)
				},
				tpClick(num){
					let voteArr = ['','投资','暂缓']
					if(state.detail.projectDetails.projectProgress == '9'){
						voteArr = ['','同意立项','暂缓']
					}
					state.tpClickNum = num
					Dialog({
						title: '确认'+voteArr[num]+'吗？',
						onCancel:event.onCancel,
						onOk:event.onOk
					});
				},
				onOk(){
					event.updateVoteStatus(state.tpClickNum)
				},
				onCancel(){
				},
				updateVoteStatus(num){
					proxy.$axios.updateVoteStatus({projectVoteId:state.projectVoteId,userId:state.user.userId,voteType:num}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success('投票成功')
							event.getVoteLastDetailByVoteId(state.projectVoteId)
							// Notification.requestPermission().then((result) => {
							//     if (result === "granted") {
							//       navigator.serviceWorker.ready.then((registration) => {
							//         registration.showNotification("投票通知", {
							//           body: "投票",
							// 		  data: {url:location.href}
							//         });
							//       });
							//     }
							//   });
						}else{
							Toast.fail(res.data.errorMsg)
						}
					})
				},
				
				people(){
					proxy.$router.push({name:'people',query:{id:state.detail.projectDetails.projectDetailsId}})
				},
				guanzhu(){
					let type = 1
					if(state.type == 1){
						type = 0
					}else{
						type = 1
					}
					proxy.$axios.focusOnProjects({userId:state.user.userId,projectDetailsId:state.detail.projectDetails.projectDetailsId,type:type}).then(res=>{
						if(res.data.errorCode == '00000'){
							event.selectFocusOnProjects()
						}
					})
				},
				selectFocusOnProjects(){
					state.type = ''
					proxy.$axios.selectFocusOnProjects({userId:state.user.userId,projectDetailsId:state.detail.projectDetails.projectDetailsId}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.type = res.data.data
						}
					})
				},
				back(){
					if(router.currentRoute.value.meta.isBack){
						if(router.currentRoute.value.meta.goIndex){
							state.isBack=true
						}
						proxy.$router.push({path:'/',query:{isBack:state.isBack}})
					}else{
						proxy.$router.go(-1)
					}
				},
				submit(){
					proxy.$axios.addProjectComments({comment:state.inputValue,commentator:state.user.userId,projectDetailsId:state.detail.projectDetails.projectDetailsId}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							state.inputValue = ''
						}
					})
				},
				edit(){
					state.showPop = true
				},
				popClick(index,item){
					state.showPop = false
					if(index == 0){
						event.selectProjectProgress()
						state.showJD = true
					}else if(index == 1){
						sessionStorage.setItem('pages',state.tab1value)
						proxy.$router.push({name:'itemLabel',query:{id:state.projectId}})
					}else if(index == 2){
						state.showMC = true
					}else if(index == 3){
						event.selectProjectCategory()
						state.showFL = true
					}else if(index == 4){
						state.showDY = true
						state.addressValue = []
					}else if(index == 5){
						state.showJJ = true
					}else if(index == 6){
						sessionStorage.setItem('pages',state.tab1value)
						proxy.$router.push({name:'itemDesc',query:{id:state.projectId}})
					}else if(index == 7){
						sessionStorage.setItem('pages',state.tab1value)
						proxy.$router.push({name:'itemCW',query:{id:state.projectId}})
					}else if(index == 8){
						state.showXY = true
					}else if(index == 9){
						uploadImg.value.click()
					}else if(index == 10){
						uploadFile.value.click()
					}
				},
				JDClick(item){
					state.showJD = false
					if(item.value == state.detail.projectDetails.projectProgress){
						Toast.warn('当前状态已经是'+item.label)
						return
					}
					event.editProjectDetails(item.value,3,state.detail.projectDetails.projectProgress)
				},
				insert(){
					let voteCategorize = 0
					if(state.detail.projectDetails.projectProgress == '9'){
						voteCategorize = 1
					}
					proxy.$axios.insert({projectDetailsId:state.detail.projectDetails.projectDetailsId,creatUserId:state.user.userId,voteCategorize:voteCategorize}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							event.getDetail()
						}
					})
				},
				FLClick(item){
					if(item.value == state.detail.projectDetails.projectCategory){
						Toast.warn('当前状态已经是'+item.label)
						return
					}
					state.showFL = false
					event.editProjectDetails(item.value,2,state.detail.projectDetails.projectCategory)
				},
				okMC(){
					state.showMC = false
					event.editProjectDetails(state.itemName,1,state.detail.projectDetails.projectName)
				},
				submitJJ(){
					state.showJJ = false
					event.editProjectDetails(state.itemJJ,5,state.detail.projectDetails.introduction)
				},
				submitJJXy(){
					state.showXY = false
					event.saveOrUpdateProtocol()
				},
				saveOrUpdateProtocol(){
					proxy.$axios.saveOrUpdateProtocol({userId:state.user.userId,projectDetailsId:state.detail.projectDetails.projectDetailsId,protocolName:state.protocolName,protocolIntroduction:state.protocolIntroduction,status:0}).then(res=>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data
							if(state.uploadData){
								event.saveOrUpdateProtocolFile(state.uploadResult,state.uploadData,obj.projectDetailsId,obj.protocolId)
							}
						}
					})
				},
				saveOrUpdateProtocolFile(data,file,projectDetailsId,protocolId){
					let type = file.name.split('.')
					proxy.$axios.saveOrUpdateProtocolFile({protocolId:protocolId,userId:state.user.userId,protocolFileType:type[type.length-1],protocolFileName:file.name,fileUploadAddress:data.name,projectDetailsId:projectDetailsId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							state.uploadResult = ''
							state.uploadData = ''
							state.uploadXYStatus = false
						}
					})
				},
				editProjectDetails(text,type,originalText){
					let obj = {operateUserId:state.user.userId,projectDetailsId:state.detail.projectDetails.projectDetailsId,text:text,type:type}
					if(originalText){
						obj.originalText = originalText
					}
					proxy.$axios.editProjectDetails(obj).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							state.isBack = true
							event.getDetail()
						}
					})
				},
				onChange(data){
					state[data.next] = data.value.child
					if(state.addressValue.indexOf(data.value) == -1){
						state.addressValue.push(data.value)
					}
					if(!state[data.next]){
						state.showDY = false
					}
				},
				close(data){
					// itemDY.value=[data.data.province.id,data.data.city.id,data.data.country.id]
					state.address = data.data.province.name+'|'+data.data.city.name+'|'+data.data.country.name
					event.editProjectDetails(state.address,4,state.detail.projectDetails.regionId)
				},
				closeMask(data){
					if(data.closeWay.value == 'cross'){
						if(state.addressValue.length == 2){
							state.address = state.addressValue[0].name+'|'+state.addressValue[1].name
							// itemDY.value=[state.addressValue[0].id,state.addressValue[1].id]
						}else if(state.addressValue.length == 1){
							state.address = state.addressValue[0].name
							// itemDY.value=[state.addressValue[0].id]
						}
						event.editProjectDetails(state.address,4,state.detail.projectDetails.regionId)
					}
				},
				fileChange(file){
					Toast.loading('上传中...')
					event.getOssStsConfig(file.target.files[0])
				},
				getOssStsConfig(file){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.oss = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: res.data.data.bucketName
							});
							event.putObject(file)
						}
					})
				},
				putObject(data){
					let videoname = proxy.$common.getDateNow('yyyyMMdd')+'/'+state.user.userId+'_'+proxy.$common.getDateNow('yyyyMMddhhmmss')+'_'+data.name
					state.oss.put(
					  videoname,
					  data
					).then(result => {
					  console.log(result)
					  if(result.res.status == 200){
					    console.log(result)
						if(state.uploadXYStatus){
							state.uploadResult = result
							state.uploadData = data
							// event.saveOrUpdateProtocolFile(result,data)
						}else{
							event.insertInformation(result,data)
						}
					  }
					}).then(function (r2) {
					  console.log('get success: %j', r2);
					}).catch(function (err) {
					  console.error('error: %j', err);
					});
				},
				insertInformation(data,file){
					let type = file.name.split('.')
					console.log(type[type.length-1])
					proxy.$axios.insertInformation({userId:state.user.userId,projectDetailsId:state.detail.projectDetails.projectDetailsId,fileName:file.name,fileType:type[type.length-1],fileUploadAddress:data.name}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							event.getInformation(function(){})
						}
					})
				},
				voteDetail(){
					if(state.VoteData1.length == 0 && state.VoteData2.length == 0 ){
						Toast.text('没有投票人员')
					}else{
						state.showTP = !state.showTP
					}
					
				}
			}
			return {
				state,
				event,
				uploadImg,
				uploadFile,
				uploadXy,
				myElement,
				itemDY
			};
		}
	}
</script>

<style>
	.detail{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		position: relative;
		overflow: hidden;
	}
	.step{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		font-size: 15px;
		margin: 15px;
	}
	.step_item{
		position: relative;
		width: 100%;
	}
	.step_item div:first-child{
		height: 30px;
		line-height: 30px;
		color: #777;
	}
	.step_item.on div:first-child{
		font-size: 15px;
		color: #6692EF;
	}
	.step_item.on ~ .step_item div:first-child{
		color: #bbb;
		font-size: 13px;
	}
	.step_item div:nth-child(2){
		width: 14px;
		height: 14px;
		background-size: 100%;
		background-repeat: no-repeat;
		position: relative;
		z-index: 2;
		margin: 0 auto;
	}
	.step_icon{
		background-image: url('../../assets/imgs/dui.png');
	}
	.on .step_icon{
		background-image: url('../../assets/imgs/zanfan_on.png');
	}
	.step_item.on ~ .step_item .step_icon{
		background-image: url('../../assets/imgs/yuan.png');
	}
	.step_item .line{
		width: 100%;
		height: 1px;
		background-color: #EAEAEA;
		position: absolute;
		bottom: 6px;
		left: 0;
		z-index: 1;
	}
	.step div:first-child .line{
		left: 50%;
	}
	.step div:last-child .line{
		left: -50%;
	}
	.tp1{
		font-size: 14px;
		margin: 0 10px 20px;
	}
	.tp_tit{
		color: #999999;
	}
	.tp_time{
		display: flex;
		justify-content: center;
		margin-top: 18px;
		color: #999;
	}
	.countdown-part-box {
	  display: flex;
	  align-items: center;
	}
	.part-item {
	  flex-shrink: 0;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  width: 25px;
	  height: 25px;
	  background: #f0f0f0;
	  color: #333;
	  font-size: 16px;
	  border-radius: 6px;
	  margin: 0 10px;
	}
	.tp_tip{
		color: #F18627;
	}
	.tp_cont{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0 15px;
	}
	.tp_cont>div{
		border-radius: 10px;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 10px;
	}
	.tp_cont>div .tp_num{
		position: absolute;
		top: -17px;
		left: 50%;
		margin-left: -17px;
		width: 31px;
		height: 31px;
		text-align: center;
		line-height: 35px;
		border: 2px solid #fff;
		font-size: 18px;
		border-radius: 19px;
		color: #fff;
	}
	.tp_cont>div .tp_t{
		margin: 38px 0 20px;
		text-align: center;
		height: 45px;
	}
	.tp_cont .tp_obj1:nth-child(2){
		background-color: #E3F5FF;
	}
	.tp_cont>div .tp_btn{
		border-radius: 15px;
		width: 75px;
		height: 30px;
		line-height: 30px;
		color: #fff;
		margin: 0 auto;
	}
	.tp_obj1{
		background-color: #FFECEC;
		border: 2px solid transparent;
	}
	.tp_obj1.on{
		border-color: #F18627;
	}
	.tp_obj2{
		background-color: #E3F5FF;
		border: 2px solid transparent;
	}
	.tp_obj2.on{
		border-color: #F18627;
	}
	.jt_icon{
		width: 60px;
		height: 60px;
		background-image: url('../../assets/imgs/vs.png');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
		margin: 0 10px;
	}
	.tab{
		text-align: left;
	}
	.tab .nut-tabs__titles{
		height: 40px;
	}
	.tab .nut-tabs__titles-item{
		color: #919191;
	}
	.tab .nut-tabs__titles-item.active{
		color: #4475DF;
	}
	.tab .nut-tabs__titles-item.active .nut-tabs__titles-item__line{
		background-image: url('../../assets/imgs/xia.png');
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100%;
		width: 65px;
		height: 3px;
		margin-top: 18px;
		position: absolute;
		bottom: 0px;
	}
	.tab .nut-tabpane{
		padding: 0;
		padding-bottom: 50px;
	}
	.bottom{
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: #fff;
		border-top: 1px solid #DBDBDB;
		width: 100%;
		height: 44px;
		padding-bottom: constant(safe-area-inset-bottom);/* 兼容 iOS < 11.2 */
		padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
	}
	.bottom>div{
		margin: 7px 10px 0;
		display: flex;
	}
	.pl_input{
		display: inline-block;
		outline: none;
		border: none;
		padding-left: 20px;
		font-size: 12px;
		height: 32px;
		line-height: 32px;
		border-radius: 17px;
		margin-right: 10px;
		background-color: #f3f3f3;
		width: calc(100% - 22px);
	}
	.tj_btn{
		display: inline-block;
		width: 75px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		background-color: #6692EF;
		color: #fff;
		border-radius: 17px;
		font-size: 12px;
	}
	.chen_bg{
		background-color: #F58068;
	}
	.longBlue_bg{
		background-color: #46B1EA;
	}
	.info{
		padding: 20px 10px 50px 20px;
		color: #939393;
	}
	.info_top{
		border-bottom: 1px solid #DEDEDE;
		font-size: 14px;
	}
	.info_top>div{
		display: flex;
		align-items: flex-start;
		text-align: left;
		margin-bottom: 20px;
	}
	.info_top>div>span{
		width: 14px;
		height: 14px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: 100%;
		margin-top: 4px;
		margin-right: 5px;
	}
	.info_top>div>div>p{
		margin: 0;
	}
	.address_icon{
		background-image: url('../../assets/imgs/address.png');
	}
	.tel_icon{
		background-image: url('../../assets/imgs/tel.png');
	}
	.line_icon{
		width: 3px;
		height: 15px;
		display: inline-block;
		background-image: url('../../assets/imgs/line.png');
		background-repeat: no-repeat;
		background-size: 100%;
		margin-right: 5px;
		position: relative;
		top: 3px;
	}
	.info_tit{
		font-size: 16px;
		color: #333;
		margin: 20px 0;
	}
	.info_bottom{
		font-size: 13px;
		text-align: left;
	}
	.laberItem{
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		color: #555;
		margin: 0 20px;
		border-bottom: 1px solid #f0f0f0;
		height: 40px;
		line-height: 40px;
	}
	.laberItem .nut-price{
		color: #333;
		margin-right: 10px;
	}
	.labelTit{
		background-color: #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 20px;
	}
	.fileItem{
		margin: 20px;
	}
	.zanfan_top{
		height: 24px;
		background-image: url('../../assets/imgs/zanfan_topbg.png');
		background-repeat: no-repeat;
		background-size: 100%;
		text-align: left;
		font-size: 16px;
		color: #fff;
		padding: 10px;
	}
	.zanfan_top span{
		width: 20px;
		height: 20px;
		display: inline-block;
		background-image: url('../../assets/imgs/time_icon.png');
		background-repeat: no-repeat;
		background-size: 100%;
		vertical-align: middle;
		margin-right: 10px;
	}
	.touhou_top{
		height: 24px;
		background-image: url('../../assets/imgs/touhou_topbg.png');
		background-repeat: no-repeat;
		background-size: 100%;
		text-align: left;
		font-size: 16px;
		color: #EA8137;
		padding: 10px;
	}
	.touhou_top span{
		width: 20px;
		height: 20px;
		display: inline-block;
		background-image: url('../../assets/imgs/touhou_pro.png');
		background-repeat: no-repeat;
		background-size: 100%;
		vertical-align: middle;
		margin-right: 10px;
	}
	.tp_over1{
		font-size: 14px;
		margin: 20px 0;
	}
	.tp_box1{
		margin: 20px 10px 0;
		display: flex;
		position: relative;
	}
	.tp_box1>div{
		height: 39px;
		background-repeat: no-repeat;
		background-size: 100% 49px;
		line-height: 20px;
		padding: 5px 0;
	}
	.tp_box1 div:first-child{
		background-image: url('../../assets/imgs/bg_voted_3_tou.png');
		color: #F58068;
	}
	.tp_box1 div:nth-child(2){
		width: 10%;
		background-image: url('../../assets/imgs/bg_voted_3_zhong.png');
	}
	.tp_box1 div:last-child{
		background-image: url('../../assets/imgs/bg_voted_3_zan.png');
		color: #46B1EA;
	}
	.tp_box1.vs1 div:nth-child(1){
		width: 70%;
	}
	.tp_box1.vs1 div:nth-child(3){
		width: 20%;
	}
	.tp_box1.vs2 div:nth-child(1){
		width: 20%;
	}
	.tp_box1.vs2 div:nth-child(3){
		width: 70%;
	}
	.tp_box1.vs3 div:nth-child(1){
		width: 45%;
	}
	.tp_box1.vs3 div:nth-child(3){
		width: 45%;
	}
	.tp_box1 div span{
		font-size: 16px;
	}
	.guanzhu_icon{
		display: inline-block;
		width: 16px;
		height: 16px;
		background-image: url('../../assets/imgs/icon_project_add.png');
		background-repeat: no-repeat;
		background-size: 100%;
		margin-right: 20px;
	}
	.guanzhu_icon.on{
		background-image: url('../../assets/imgs/icon_project_unadd.png');
	}
	.people_icon{
		display: inline-block;
		width: 16px;
		height: 16px;
		background-image: url('../../assets/imgs/uuu.png');
		background-repeat: no-repeat;
		background-size: 100%;
	}
	.edit_icon{
		width: 45px;
		height: 45px;
		background-image: url('../../assets/imgs/addItem.png');
		background-size: 100%;
		background-repeat: no-repeat;
		position: fixed;
		bottom: 80px;
		right: 30px;
	}
	.tp_p_cont{
		padding-bottom: constant(safe-area-inset-bottom);/* 兼容 iOS < 11.2 */
		padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
	}
	.tpp_tit{
		font-weight: bold;
		margin: 5px 0px 10px;
		font-size: 16px;
	}
	.tpp_txt{
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		width: 100%;
		max-height: 50vh;
	}
	.tpp_c_t{
		display: flex;
		justify-content: space-around;
		height: 30px;
		line-height: 30px;
		width: 100%;
		
	}
	.tpp_c_t div:nth-child(1){
		color: #F58068;
		width: 48%;
	}
	.tpp_c_t div:nth-child(2){
		color: #46B1EA;
		width: 48%;
	}
	.tpp_txt>div{
		width: 48%;
	}
	.tpp_txt>div:nth-child(1) .tpp_c_li{
		background: #FFECEC;
	}
	.tpp_txt>div:nth-child(2) .tpp_c_li{
		background: #E3F5FF;
	}
	.tpp_c_li{
		display:flex;
		padding: 10px;
		align-items: center;
	}
	.tpp_c_li>span{
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		border-radius: 20px;
		overflow: hidden;
		margin-right: 10px;
	}
	.tpp_c_li>span>img{
		height: 100%;
		width: 100%;
	}
	.tpp_c_li .tpp_name{
		text-align: left;
	}
	.tpp_c_li .tpp_date{
		font-size: 12px;
		color: #bbb;
		text-align: left;
	}
	.mosaic{
		filter: blur(4px);
	}
	.next_btn1{
		width: 80vw;
		margin: 20px auto;
		height: 40px;
		line-height: 40px;
		color: #fff;
		background-color: #5484EA;
		border-radius: 25px;
		text-align: center;
		box-shadow: 2px 2px rgba(0,0,0,0.15);
	}
	.next_btn1:active{
		background-color: #3363c7;
		box-shadow: 2px 2px rgba(0,0,0,0.25);
	}
	.middle{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: 14px;
		color: #73b4ef;
	}
	.tp_Item{
		text-align: left;
		font-size: 14px;
	}
	.tp_Item .tp_l{
		padding: 5px 20px;
	}
	.btn_Item{
		display: flex;
		justify-content: space-between;
		padding: 10px 40px;
		border-top: 1px solid #f0f0f0;
	}
	.edit_icon1{
		
	}
	.detail .nut-navbar__title{
		max-width: 62%;
	}
</style>