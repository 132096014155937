<template>
	<div class="label area-inset-top">
		<nut-navbar @on-click-back="event.back" title="笔记详情" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<div class="page_note">
			<div class="note_li">
				<div class="note_li_l">
					<div>
						<img :src="state.userRespDto.avatarPath" alt="" v-if="state.userRespDto.avatarPath">
						<span v-else>{{state.userRespDto.nickName}}</span>
					</div>
				</div>
				<div class="note_li_r">
					<div class="note_userName" v-if="state.userRespDto.nickName">{{state.userRespDto.nickName}}</div>
					<div class="note_time">发布于：{{state.detail.updateTime}}</div>
				</div>
			</div>
			<div class="note_detail" v-html="state.detail.text"></div>
		</div>
	</div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, toRefs, ref } from 'vue';
import { Toast,Dialog } from '@nutui/nutui';

 
	export default {
		setup() {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId: '',
				detail: '',
				userRespDto: ''
			})
			onMounted(()=>{
				state.projectId = proxy.$route.query.id
				event.getDetail()
			})
			const event = {
				back(){
					proxy.$router.go(-1)
				},
				getDetail(e){
					proxy.$axios.getOgzNoteDetails({organizationNoteId:proxy.$route.query.id}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.detail = res.data.data
							state.userRespDto = res.data.data.userRespDto
						}
					})
				},
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.page_note{
		overflow: scroll;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100 - 50px);
	}
	.note_li{
		display: flex;
		margin: 10px;
	}
	.note_li_l{
		width: 50px;
		height: 50px;
		border-radius: 25px;
		overflow: hidden;
	}
	.note_li_l img{
		width: 100%;
		height: 100%;
	}
	.note_li_r{
		text-align: left;
		margin-left: 10px;
	}
	.note_time{
		font-size: 12px;
		margin-top: 10px;
	}
	.note_detail{
		padding: 10px;
		text-align: left;
	}
</style>