<template>
	<div class="detail area-inset-top">
		<nut-navbar @on-click-back="event.back" :title="state.title" style="box-shadow: none;" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<div class="item_tab_span" style="text-align: left;overflow-x: scroll;white-space: nowrap;">
			<span style="margin-bottom:5px;" v-for="item in state.detail.labelList">{{item.labelName}}</span>
		</div>
		<div class="tab" ref="myElement">
			<!-- sticky :top="state.topV" -->
			<nut-tabs v-model="state.tab1value" title-gutter="10" background="#f7f7f7" color="#919191">
				<template v-slot:titles>
					<div class="nut-tabs__titles-item" @click="event.tabClick(item)" :class="{active:state.tab1value==item.paneKey}" :key="item.paneKey"  v-for="item in state.list4">
						<span class="nut-tabs__titles-item__text">{{item.title}}</span>
						<span class="nut-tabs__titles-item__line"></span>
					</div>
				</template>
				<nut-tabpane>
					<template v-slot:default v-if="state.tab1value == 0">
						<div id="scroll" class="" :style="'height:calc(100vh - '+state.height+'px);margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'">
							<nut-infiniteloading
							 containerId = 'scroll'
							 load-icon="loading1"
							 pull-icon="loading1"
							:use-window='false'
							:has-more="state.hasMore"
							:is-open-refresh="true"
							 @load-more="event.loadMore"
							 @refresh="event.refresh"
							>
								<div class="info_content">
									<div class="trends_list" v-for="(item,index) in state.listData">
										<div class="list_tit">{{index}}</div>
										<div class="list_item" v-for="(itemChild,indexChild) in item">
											<div class="item_left">
												<div>
													<img v-if="itemChild.userRespDto" alt="" :src="itemChild.userRespDto.avatarPath">
												</div>
											</div>
											<div class="item_right">
												<div class="right_tit">
													<span>{{itemChild.userRespDto.nickName}}</span>
													<span>{{itemChild.createTime.split(' ')[1]}}</span>
												</div>
												<div class="right_txt">
													<div class="text" ref="lineClamp">
														<span class="btn" @click="event.detail(itemChild)">...全文</span>
														<div class="text-con" ref="textMore" v-html="itemChild.text"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="bottom">
									<div>
										<input type="text" v-model="state.inputValue" class="pl_input" placeholder="记一笔与该机构的交流笔记,会发布在动态里">
										<span class="tj_btn" @click="event.submit">提交</span>
									</div>
								</div>
							</nut-infiniteloading>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 1">
						<div class="js" :style="'height:calc(100vh - '+(state.height-90)+'px);margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'">
							<div class="info_tit"><span class="line_icon"></span>机构简介</div>
							<div style="padding-bottom: 20px;">{{state.detail.introduction}}</div>
							<div class="line"></div>
							<div class="info_tit"><span class="line_icon"></span>管理资本规模</div>
							<div style="padding-bottom: 20px;">{{state.detail.totalAssets}}</div>
							<div class="line"></div>
							<div class="info_tit"><span class="line_icon"></span>备注</div>
							<div style="padding-bottom: 20px;">{{state.detail.remarks}}</div>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 2">
						<div class="list" :style="'height:calc(100vh - '+(state.height-90)+'px);width: 100%;margin: 0;background: #f2f2f2;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'" id="scroll1">
							<div class="list_top">
								<div style="color: #666;" @click="state.showPop = true">
									{{state.typeList[state.type-1]}}
									<span class="xiala_icon xiala_icon_hui"></span>
								</div>
								<div class="tp_btn1" @click="event.edit">上传资料</div>
							</div>
							<nut-infiniteloading
							 containerId = 'scroll1'
							 pull-icon="loading1"
							:use-window='false'
							:is-open-refresh="true"
							:has-more="false"
							 @refresh="event.refresh1"
							>
								<zl-component :listData="state.fileData" @fileStatus="event.getStatus"></zl-component>
							</nut-infiniteloading>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 3">
						<div class="list" :style="'height:calc(100vh - '+(state.height-100)+'px - 50px);background: #f2f2f2;margin: 0 auto;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'" id="scroll">
							<div>
								<div class="team_li" v-for="item in state.teamData">
									<div class="team_li_l">
										<div>
											<img v-if="item.avatarPath" :src="item.avatarPath" alt="">
											<span v-else>{{item.contactName}}</span>
										</div>
									</div>
									<div class="team_li_c">
										<div>{{item.contactName}}</div>
										<div>{{item.telephone}}</div>
									</div>
									<div class="team_li_r">{{item.job}}</div>
								</div>
							</div>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
						<div v-if="state.teamData.length == 0" class="middle" :style="'height:calc(100vh - '+state.height+'px - 50px);'">
							<img src="../../assets/imgs/empt.png" alt="" style="margin-bottom: 30px;">
							<div>空空如也</div>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 4">
						<div class="list" :style="'height:calc(100vh - '+(state.height-100)+'px);width: calc(100% - 40px);padding: 20px;background: #f2f2f2;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);'" id="scroll">
							<nut-infiniteloading
							 containerId = 'scroll'
							 load-icon="loading1"
							 pull-icon="loading1"
							:use-window='false'
							:has-more="state.hasMore"
							:is-open-refresh="true"
							 @load-more="event.loadMore"
							 @refresh="event.refresh"
							>
								<xm-component :listData="state.list"></xm-component>
							</nut-infiniteloading>
							<div :style="'padding-bottom: constant(safe-area-inset-bottom);padding-bottom: env(safe-area-inset-bottom);height:'+state.iosHeight"></div>
						</div>
					</template>
				</nut-tabpane>
			</nut-tabs>
		</div>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.showPop">
			<div class="popup_li" v-for="(item,index) in state.typeList" @click="event.popClick(index,item)">
				{{item}}
			</div>
		</nut-popup>
		<input type="file" ref="uploadFile" @change="event.fileChange" style="width:0;height:0;opacity: 0;">
		<file-p v-if="state.fileBox" :url="state.fileUrl" :type="state.fileType" @fileClose="event.getStatus"></file-p>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, ref, computed, onUnmounted, nextTick} from 'vue';
	import { Dialog, Toast } from '@nutui/nutui';
	import dtComponent from '@/view/trends/list'
	import zlComponent from '@/view/file/list'
	import xmComponent from '@/view/item/list'
	import fileP from '@/view/file/iframe'
	import oss from 'ali-oss'
	import city from '@/components/city2.json'
	export default {
		components:{
			dtComponent,
			zlComponent,
			xmComponent,
			fileP
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const uploadFile = ref(null)
			const myElement = ref(null)
			const lineClamp = ref(null)
			const textMore = ref(null)
			const state = reactive ({
				step: 3,
				height: 0,
				topV: 0,
				projectId: 0,
				tab1value: 0,
				tabChildvalue: 0,
				tabChildvalue1: 0,
				type:1,
				typeList:['按时间','按名称','按类型'],
				list4: [
					{
						title:'交流笔记',
						paneKey: '0',
					},
					{
						title:'介绍',
						paneKey: '1',
					},
					{
						title:'资料',
						paneKey: '2',
					},
					{
						title:'团队',
						paneKey: '3',
					},
					{
						title:'关联项目',
						paneKey: '4',
					}
				],
				detail:{projectDetails:{projectProgress:'0'}},
				listData:[],
				fileData:[],
				fileDataXy:[],
				cur:1,
				haMore: true,
				haMore1: true,
				user:[],
				title:'',
				inputValue: '',
				iosHeight: '44px',
				fileUrl: '',
				fileBox: false,
				fileType: '',
				teamData: [],
				listMore:[]
			});
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
				event.getDetail()
				state.time = setTimeout(function(){
					state.topV = 45
					state.height = myElement.value.offsetTop+90
				},1000)
				const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
				if (isInStandaloneMode()) {
				}else {
					console.log('浏览器')
					state.iosHeight = '100px'
				}
			})
			onUnmounted(()=>{
				clearTimeout(state.time)
			})
			const event = {
				detail(item){
					proxy.$router.push({name:'noteDetail',query:{id:item.organizationNoteId}})
				},
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
					state.fileType = res.fileType
					console.log(res)
				},
				getDetail(){
					state.detail = ''
					proxy.$axios.getOgzIntroduceById({organizationId:proxy.$route.query.id}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.detail = res.data.data
							state.title = state.detail.name
							event.getOgzNotePage(state.cur,function(){})
						}
					})
				},
				tabClick(item){
					state.tab1value = item.paneKey
					state.height = myElement.value.offsetTop+90
					if(item.paneKey == 0){
						state.cur = 1
						event.getOgzNotePage(state.cur,function(){})
					}else if(item.paneKey == 1){
						
					}else if(item.paneKey == 2){
						event.getOgzFileByOgzId(state.type,function(){})
					}else if(item.paneKey == 3){
						event.getOgzTeamByOgzId()
					}else if(item.paneKey == 4){
						event.getOgzProjectListByOgzId()
					}
				},
				getOgzNotePage(curpage,fun){
					proxy.$axios.getOgzNotePage({currentPage:curpage,pageSize:10,organizationId:state.projectId}).then(res=>{
						if(res.data.errorCode == '00000'){
							let data = res.data.data.list
							let obj = {}
							if(data != null){
								for(var i = 0;i<data.length;i++){
									let objId = data[i].createTime.split(' ')[0]
									if(obj[objId]){
										state.listMore.push({more:0})
										obj[objId].push(data[i])
									}else{
										state.listMore.push({more:0})
										obj[objId] = []
										obj[objId].push(data[i])
									}
								}
								if(curpage!=1){
									Object.keys(obj).forEach(key=>{
										if(state.listData[key]){
											state.listData[key] = state.listData[key].concat(obj[key])
										}else{
											state.listData[key] = obj[key]
										}
									})
								}else{
									state.listData = obj
								}
							}else{
								state.listData = obj
							}
							if(curpage < res.data.data.pages){
								state.hasMore = true
							}else{
								state.hasMore = false
							}
							nextTick(() => {
								let leng = lineClamp.value.length
								for(var i = (curpage-1)*10;i<leng;i++){
									if (textMore.value[i].scrollHeight > lineClamp.value[i].clientHeight) {
										lineClamp.value[i].children[0].style="display:block;"
									}else {
										lineClamp.value[i].children[0].style="display:none;"
									}
								}
							})
							fun()
						}
					})
				},
				getOgzFileByOgzId(type,fun){
					proxy.$axios.getOgzFileByOgzId({organizationId:state.projectId,sortType:type}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.fileData = res.data.data
							fun && fun()
						}
					})
				},
				getOgzTeamByOgzId(){
					proxy.$axios.getOgzTeamByOgzId({organizationId:state.projectId}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.teamData = res.data.data
						}
					})
				},
				getOgzProjectListByOgzId(){
					proxy.$axios.getOgzProjectListByOgzId({organizationId:state.projectId,userId:state.user.userId,userRole:state.user.role}).then(res=>{
						if(res.data.errorCode == '00000'){
							state.list = res.data.data
						}
					})
				},
				loadMore(done){
					state.cur = state.cur+1
					event.getOgzNotePage(state.cur,done)
				},
				refresh(done){
					state.cur = 1
					event.getOgzNotePage(state.cur,done)
				},
				refresh1(done){
					event.getOgzFileByOgzId(state.type,done)
				},
				back(){
					if(router.currentRoute.value.meta.isBack){
						if(router.currentRoute.value.meta.goIndex){
							state.isBack=true
						}
						proxy.$router.push({path:'/',query:{isBack:state.isBack}})
					}else{
						proxy.$router.go(-1)
					}
				},
				submit(){
					proxy.$axios.saveOrUpdateOgzNote({text:state.inputValue,organizationId:state.detail.organizationId,userId:state.user.userId}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							state.inputValue = ''
						}
					})
				},
				edit(){
					uploadFile.value.click()
				},
				fileChange(file){
					Toast.loading('上传中...')
					event.getOssStsConfig(file.target.files[0])
				},
				getOssStsConfig(file){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.oss = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: res.data.data.bucketName
							});
							event.putObject(file)
						}
					})
				},
				putObject(data){
					let videoname = proxy.$common.getDateNow('yyyyMMdd')+'/'+state.user.userId+'_'+proxy.$common.getDateNow('yyyyMMddhhmmss')+'_'+data.name
					state.oss.put(
					  videoname,
					  data
					).then(result => {
					  console.log(result)
					  if(result.res.status == 200){
					    console.log(result)
						event.ogzFileUpload(result,data)
					  }
					}).then(function (r2) {
					  console.log('get success: %j', r2);
					}).catch(function (err) {
					  console.error('error: %j', err);
					});
				},
				ogzFileUpload(data,file){
					let type = file.name.split('.')
					proxy.$axios.ogzFileUpload({userId:state.user.userId,organizationId:state.detail.organizationId,fileName:file.name,fileType:type[type.length-1],fileUploadAddress:data.name}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
						}
					})
				},
				popClick(type,item){
					state.type = type+1
					event.getOgzFileByOgzId(state.type,function(){})
					state.showPop = false
				},
			}
			return {
				state,
				event,
				uploadFile,
				myElement,
				lineClamp,
				textMore
			};
		}
	}
</script>

<style>
	.detail{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		position: relative;
		overflow: hidden;
	}
	.info_content{
		font-size: 12px;
	}
	.js{
		padding: 0 10px;
	}
	.line{
		height: 2px;
		background-color: rgb(247, 247, 247);
	}
	.list_top{
		padding:10px;
		display:flex;
		justify-content: space-between;
	}
	.team_li{
		display:flex;
		justify-content: space-around;
		font-size: 14px;
		color: #333;
		border-bottom: 1px solid #f2f2f2;
		padding: 0 10px;
		align-items: center;
		background-color: #fff;
	}
	.team_li:last-child{
		border-bottom: none;
	}
	.team_li_l{
		width: 50px;
		height: 50px;
		line-height: 50px;
		border-radius: 50px;
		overflow: hidden;
		margin-right: 10px;
		background: #4475DF;
		color: #fff;
	}
	.team_li_l>div{
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		border-radius: 50px;
		overflow: hidden;
	}
	.team_li_l img{
		width: 100%;
		height: 100%;
	}
	.team_li_c{
		flex: 1;
		line-height: 30px;
	}
	.team_li_r,.tp_btn1{
		background: #4475DF;
		color: #fff;
		padding: 2px 5px;
		border-radius: 6px;
		font-size: 14px;
	}
	.right_txt {
	  display: flex;
	}
	.text {
	  line-height: 20px;
	  display: -webkit-box;
	  -webkit-line-clamp: 3;
	  overflow: hidden;
	  -webkit-box-orient: vertical;
	  position: relative;
	}
	/* .text::before {
	  float: right;
	  height: calc(100% - 20px);
	  content: "";
	} */
	.btn {
	 /* float: right;
	  clear: both; */
	  position: absolute;
	  bottom: 0;
	  right: 0;
	  color: #206ef7;
	  cursor: pointer;
	  background-color: #fff;
	}
</style>