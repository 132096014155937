<template>
  <div class="login area-inset-top">
	<div class="login_content">
		<div class="login_frist">
			<img src="../../assets/imgs/logo.png" alt="" class="logo">
			<input class="input_tel" v-model="state.phone" type="tel" maxlength="11" placeholder="请输入您的手机号">
			<div class="next_btn" @click="event.next">下一步</div>
		</div>
	</div>
  </div>
</template>

<script>
	import { reactive, onMounted, ref, getCurrentInstance } from 'vue';
	import { Toast } from '@nutui/nutui';
	export default {
		setup() {
			const attr = ref(null)
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				phone: '',
			});
			onMounted(()=>{
				
			});
			const event = {
				next(){
					let mobileReg = /^1[3-9]\d{9}$/
					if(!mobileReg.test(state.phone)){
						Toast.fail('请输入正确的手机号！')
						return
					}
					proxy.$axios.getVerificationCode({phone:state.phone}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success('验证码发送成功请注意查收')
							proxy.$router.push({name:'loginNext',query:{phone:state.phone}})
						}
					})
				},
			}
			return { 
				state,
				event
			};
		}
	}
	
</script>

<style>
	.login{
		background-image: url('../../assets/imgs/top_bg.png');
		background-repeat: no-repeat;
		background-size: 100% 200px;
		background-position: top left;
		background-color: #fff;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		overflow: hidden;
	}
	.login .logo{
		margin: 30px auto 45px;
		width: 109px;
		height: 100px;
		display: block;
	}
	.input_tel{
		width: 80vw;
		height: 50px;
		line-height: 50px;
		padding: 0 5vw;
		border-radius: 25px;
		background-color: #F6F6F8;
		color: #333;
		font-size: 14px;
		outline: none;
		border: none;
	}
	.next_btn{
		width: 90vw;
		margin: 20px auto;
		height: 50px;
		line-height: 50px;
		color: #fff;
		background-color: #5484EA;
		border-radius: 25px;
		text-align: center;
	}
</style>