<template>
	<div class="people area-inset-top">
		<nut-navbar @on-click-back="event.back" title="项目中成员" fixed class="navCom" :safe-area-inset-top="true">
			<template #left>
			  <nut-icon name="rect-left" color="#777"></nut-icon>
			</template>
		</nut-navbar>
		<div style="margin: 0 10px;background-color: #fff;">
			<nut-checkboxgroup v-model="state.checkOnlist">
				<nut-checkbox v-for="item in state.checkList" :label="item.userId" :disabled="state.disabledList.indexOf(item.userId) != -1" @change="event.changeBox(item)">
					<div class="checkLi">
						<img :src="item.avatarPath || state.userDefalut" alt="">
						{{item.nickName}}
					</div>
				</nut-checkbox>
			</nut-checkboxgroup>
		</div>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
	export default {
		setup() {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId:'',
				user:[],
				checkList:[],
				checkOnlist:[],
				disabledList:[],
				userDefalut: require('../../assets/imgs/user_defalut.png')
			})
			onMounted(()=>{
				state.projectId = proxy.$route.query.id
				state.user = JSON.parse(localStorage.getItem('user'))
				event.getUserByProjectId()
				event.getAllUser()
			})
			const event = {
				back(){
					proxy.$router.go(-1)
				},
				getAllUser(){
					proxy.$axios.getAllUser().then(res=>{
						if(res.data.errorCode == '00000'){
							state.checkList = res.data.data
							state.checkList.forEach(item=>{
								if(item.projectManager == 1 || item.isAdmin == 1){
									state.checkOnlist.push(item.userId)
									state.disabledList.push(item.userId)
								}
							})
							console.log(state.disabledList)
						}
					})
				},
				getUserByProjectId(){
					proxy.$axios.getUserByProjectId({projectId:state.projectId}).then(res=>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data
							obj.forEach(item=>{
								if(item.projectManager == 1 || item.isAdmin == 1){
									if(state.disabledList.indexOf(item.userId) == -1){
										state.checkOnlist.push(item.userId)
										state.disabledList.push(item.userId)
									}
								}
								state.checkOnlist.push(item.userId)
							})
							console.log(state.disabledList)
						}
					})
				},
				addProjectParticipants(id){
					proxy.$axios.addProjectParticipants({projectId:state.projectId,userId:state.user.userId,userIds:id}).then(res=>{
						if(res.data.errorCode == '00000'){
							// event.getUserByProjectId()
						}
					})
				},
				deleteProjectParticipants(id){
					proxy.$axios.deleteProjectParticipants({projectId:state.projectId,userId:state.user.userId,userIds:id}).then(res=>{
						if(res.data.errorCode == '00000'){
							// event.getUserByProjectId()
						}
					})
				},
				changeBox(item){
					if(state.checkOnlist.indexOf(item.userId) == -1){
						event.addProjectParticipants(item.userId)
					}else{
						event.deleteProjectParticipants(item.userId)
					}
				}
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.navCom{
		box-shadow: none;
		margin-bottom: 0;
	}
	.people{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		background-color: #f7f7f7;
	}
	.checkLi{
		height: 50px;
		line-height: 50px;
		text-align: left;
	}
	.checkLi img{
		width: 40px;
		height: 40px;
		border-radius: 20px;
		vertical-align: middle;
		margin-right: 10px;
	}
	.nut-checkbox{
		border-bottom: 1px solid #f7f7f7;
		padding-left: 20px;
	}
	.nut-checkbox__icon{
		color: #6c95ea !important;
	}
	.nut-checkbox__icon--disable {
	    color: #b6c8eb !important;
	}
</style>