<template>
	<div class="search area-inset-top">
		<div class="search_top">
			<nut-icon name="rect-left" @click="event.back" color="#777"></nut-icon>
			<div class="input_box" ref="input_box">
				<div ref="input_sc">
					<div class="tabBox" v-for="(item,index) in state.tabListOn">
						<span>{{state.tabListOnTxt[index]}}</span>
						<nut-icon name="close" size="8" color="#aaa" @click="event.close(index)"></nut-icon>
					</div>
				</div>
				<input @focus="state.step = 1" v-model="state.searchValue" type="text" placeholder="请输入关键词搜索项目内容">
			</div>
			<span @click="event.search(function(){})">搜索</span>
		</div>
		<div class="search_tab" v-if="state.step == 1" style="margin-top: 100px;">
			<div class="tab_tit">项目标签</div>
			<span v-for="item in state.tablist" :class="{'on':state.tabListOn.indexOf(item.labelId) != -1}" @click="event.labClick(item)">{{item.labelName}}</span>
		</div>
		<div class="list" v-if="state.step == 2" id="list" style="height: calc(100vh - 100px);overflow-y: auto;margin-top: 100px;">
			<nut-infiniteloading
			      load-txt="加载中..."
				  pull-txt="   "
			      load-more-txt="没有啦～"
			      container-id="list"
			      :use-window="false"
				  :is-open-refresh="true"
			      :has-more="state.customHasMore"
				  load-icon="loading1"
				  pull-icon="loading1"
			      @load-more="customLoadMore"
				  @refresh="refresh"
			    >
				<xm-component :listData="state.list"></xm-component>
			</nut-infiniteloading>
		</div>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs, ref } from 'vue';
	import xmComponent from '@/view/item/list'
	export default {
		components:{
			xmComponent
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const input_box = ref(null)
			const input_sc = ref(null)
			const state = reactive ({
				searchValue: '',
				step: 1,
				user:[],
				tabListOn:[],
				tabListOnTxt:[],
				tablist:[],
				list:[],
				cur:1,
				customHasMore:false
			})
			const customLoadMore = done => {
				state.cur++
				event.search(done)
			}
			const refresh = done => {
				state.cur = 1
				state.list = []
				event.search(done)
			}
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				event.list()
			})
			const event = {
				back(){
					proxy.$router.go(-1)
				},
				list(){
					proxy.$axios.list().then(res=>{
						if(res.data.errorCode == '00000'){
							state.tablist = res.data.data
						}
					})
				},
				search(fun){
					state.step = 2
					let type = 0
					if(state.tabListOn.length != 0){
						type = 3
					}
					proxy.$axios.projectListQueryPage({currentPage:state.cur,pageSize:10,projectProgress:0,type:type,userId:state.user.userId,userRole:state.user.role,projectName:state.searchValue,labelIds:state.tabListOn}).then(res =>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data
							state.customHasMore = obj.hasNextPage
							state.list = obj.list
							fun()
						}
					})
				},
				labClick(item){
					if(state.tabListOn.indexOf(item.labelId) != -1){
						let index = state.tabListOn.indexOf(item.labelId)
						state.tabListOn.splice(index,1)
						state.tabListOnTxt.splice(index,1)
					}else{
						state.tabListOn.push(item.labelId)
						state.tabListOnTxt.push(item.labelName)
					}
					setTimeout(function(){
						input_box.value.scrollTo({ left: input_sc.value.clientWidth+100})
					},10)
				},
				close(index){
					state.tabListOn.splice(index,1)
					state.tabListOnTxt.splice(index,1)
				}
			}
			return { state,event,toRefs,customLoadMore,refresh,input_box,input_sc };
		}
	}
</script>

<style>
	.search{
		min-height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		background-color: #f7f7f7;
		overflow: hidden;
	}
	.navCom{
		box-shadow: none;
		margin-bottom: 0;
	}
	.people{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		background-color: #f7f7f7;
	}
	.checkLi{
		height: 50px;
		line-height: 50px;
		text-align: left;
	}
	.checkLi img{
		width: 40px;
		height: 40px;
		border-radius: 20px;
		vertical-align: middle;
		margin-right: 10px;
	}
	.nut-checkbox{
		border-bottom: 1px solid #f7f7f7;
		padding-left: 20px;
	}
	.nut-checkbox__icon{
		color: #6c95ea;
	}
	.search_top{
		border-bottom: 1px solid #ddd;
		display: flex;
		font-size: 14px;
		align-items: center;
		padding: 10px 10px 20px 20px;
		text-align: center;
		position: fixed;
		top: 0;
		left: 0;
		width: calc(100vw - 30px);
	}
	.search_top .input_box{
		display: flex;
		flex: 1;
		height: 30px;
		padding: 5px;
		border: 1px solid #ddd;
		margin: 0 10px;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		overflow-y: hidden;
	}
	.search_top .input_box>div{
		display: flex;
	}
	.search_top .input_box::-webkit-scrollbar{
		width: 0;
		height: 0;
	}
	.input_box input{
		outline: none;
		border: none;
		flex: 1;
		background-color: transparent;
	}
	.input_box input::placeholder{
		color: #aaa;
	}
	.tabBox{
		padding: 5px 10px;
		color: #6c95ea;
		background-color: #EDEFFF;
		border-radius: 4px;
		font-size: 14px;
		margin-right: 5px;
		white-space: nowrap;
	}
	.tabBox span{
		margin-right: 5px;
	}
	.search_tab{
		text-align: left;
		margin: 0 15px;
	}
	.tab_tit{
		margin: 20px 0;
		font-size: 15px;
	}
	.search_tab span{
		display: inline-block;
		padding: 0 8px;
		font-size: 14px;
		height: 20px;
		line-height: 20px;
		border-radius: 10px;
		border: 1px solid #ddd;
		margin-right: 5px;
		color: #888;
		max-width: 240px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.search_tab span.on{
		color: #6c95ea;
		border-color: #6c95ea;
	}
	.list{
		margin: 10px;
	}
</style>