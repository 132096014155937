<template>
	<div class="label area-inset-top">
		<nut-navbar @on-click-back="event.back" title="项目标签" :fixed="true" :safe-area-inset-top="true" z-index="9999">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		    <template #right>
				<span style="font-size: 12px;color: #6c95ea;" @click="event.save">保存</span>
		    </template>
		</nut-navbar>
		<div class="search_tab">
			<div class="tab_tit">选择的标签:</div>
			<span class="on" v-for="item in state.tabListOnTxt">{{item}}</span>
			<span @click="event.addLabel">添加标签</span>
		</div>
		<div class="search_tab">
			<div class="tab_tit">全部标签</div>
			<span v-for="item in state.tablist" :class="{'on':state.tabListOn.indexOf(item.labelId) != -1}" @click="event.labClick(item)">{{item.labelName}}</span>
		</div>
		<nut-dialog
			teleport="#app"
			title="请输入标签名称"
			v-model:visible="state.addLabelState"
			customClass="dialog_reset"
			:onOk="event.ok"
		>
		<input type="text" class="input_label" placeholder="请输入标签名称" v-model="state.labelName">
		</nut-dialog>
	</div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
import { Toast,Dialog } from '@nutui/nutui';
import { parse } from 'vue/compiler-sfc';
	export default {
		name:'itemLabel',
		props:{
			clickFun :Function,
			go: String,
			value: Array,
			valueId: Array
		},
		setup(props) {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId: '',
				user:[],
				tabListOn:[],
				tabListOnTxt:[],
				tabListOnC:[],
				tabListOnTxtC:[],
				tablist:[],
				labelName: '',
				addLabelState: false,
				go: 0,
			})
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
				state.go = props.go
				event.list()
				if(state.go == '1'){
					state.tabListOnTxt = JSON.parse(JSON.stringify(props.value))
					state.tabListOn = JSON.parse(JSON.stringify(props.valueId))
					state.tabListOnTxtC = JSON.parse(JSON.stringify(props.value))
					state.tabListOnC = JSON.parse(JSON.stringify(props.valueId))
				}
				if(state.projectId){
					event.detail()
				}
			})
			const event = {
				back(){
					Dialog({
						title: '提示',
						content: '标签未保存，确定返回？',
						customClass:'dialog_reset',
						onCancel:function(){
							
						},
						onOk:function(){
							if(state.go == '1'){
								console.log(state.tabListOnTxtC)
								console.log(state.tabListOnTxt)
								props.clickFun(state.tabListOnC,state.tabListOnTxtC)
							}else{
								proxy.$router.go(-1)
							}
						}
					});
				},
				detail(){
					proxy.$axios.projectDetailsQuery(state.projectId).then(res=>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data.projectLabelDtoList
							obj.forEach(item=>{
								state.tabListOn.push(item.labelId)
								state.tabListOnTxt.push(item.labelName)
							})
						}
					})
				},
				list(fun){
					proxy.$axios.list().then(res=>{
						if(res.data.errorCode == '00000'){
							fun && fun(res.data.data[0])
							state.tablist = res.data.data
						}
					})
				},
				save(){
					if(state.go == '1'){
						state.tabListOnC = JSON.parse(JSON.stringify(state.tabListOn))
						state.tabListOnTxtC = JSON.parse(JSON.stringify(state.tabListOnTxt))
						props.clickFun(state.tabListOnC,state.tabListOnTxtC)
					}else{
						proxy.$axios.addProjectLabel({operateUserId:state.user.userId,projectDetailsId:state.projectId,labelIds:state.tabListOn}).then(res =>{
							if(res.data.errorCode == '00000'){
								proxy.$router.go(-1)
							}
						})
					}
				},
				labClick(item){
					if(state.tabListOn.indexOf(item.labelId) != -1){
						let index = state.tabListOn.indexOf(item.labelId)
						state.tabListOn.splice(index,1)
						state.tabListOnTxt.splice(index,1)
					}else{
						state.tabListOn.push(item.labelId)
						state.tabListOnTxt.push(item.labelName)
					}
				},
				addLabel(){
					state.addLabelState = true
				},
				ok(){
					state.addLabelState = false
					proxy.$axios.create({labelName:state.labelName}).then(res=>{
						if(res.data.errorCode == '00000'){
							event.list(function(data){
								state.tabListOn.push(data.labelId)
								state.tabListOnTxt.push(data.labelName)
							})
						}
					})
				}
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.navCom{
		box-shadow: none;
		margin-bottom: 0;
	}
	.people{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		background-color: #f7f7f7;
	}
	.checkLi{
		height: 50px;
		line-height: 50px;
		text-align: left;
	}
	.checkLi img{
		width: 40px;
		height: 40px;
		border-radius: 20px;
		vertical-align: middle;
		margin-right: 10px;
	}
	.nut-checkbox{
		border-bottom: 1px solid #f7f7f7;
		padding-left: 20px;
	}
	.nut-checkbox__icon{
		color: #6c95ea;
	}
	.tabBox{
		padding: 5px 10px;
		color: #6c95ea;
		background-color: #EDEFFF;
		border-radius: 4px;
		font-size: 14px;
		margin-right: 5px;
		white-space: nowrap;
	}
	.tabBox span{
		margin-right: 5px;
	}
	.search_tab{
		text-align: left;
		margin: 0 15px;
	}
	.tab_tit{
		margin: 20px 0;
		font-size: 15px;
	}
	.search_tab span{
		display: inline-block;
		padding: 0 8px;
		font-size: 14px;
		height: 20px;
		line-height: 20px;
		border-radius: 10px;
		border: 1px solid #ddd;
		margin-right: 5px;
		color: #888;
		max-width: 240px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.search_tab span.on{
		color: #6c95ea;
		border-color: #6c95ea;
	}
	.list{
		margin: 10px;
	}
</style>