<template>
  <div class="">
		<div class="item_list item_list1" v-for="pro in state.listObj" @click="event.detail(pro)">
			<div class="item_title">{{pro.protocolName}}</div>
			<div class="item_txt">
				最后更新于{{pro.updateTime}}
			</div>
			<div class="item_txt1">
				{{pro.protocolIntroduction}}
			</div>
			<div class="item_date">
				<span class="item_head" v-if="pro.avatarPath">
					<img v-if="pro.avatarPath" :src="pro.avatarPath">
				</span>
				<span class="item_head" v-else></span>
				<span style="margin: 0 5px;color: #000;">{{pro.nickName}}</span>
				<span class="item_right">创建于{{pro.createTime}}</span>
			</div>
		</div>
  </div>
</template>
<script>
	import { reactive, onMounted, getCurrentInstance, onUpdated } from 'vue'
	export default{
		props:{
			listData: Array,
			title: String
		},
		setup(props){
			const { proxy } = getCurrentInstance()
			const state = reactive({
				listObj: {},
				title: ''
			})
			onMounted(()=>{
				state.listObj = props.listData
				state.title = props.title
			})
			onUpdated(()=>{
				state.listObj = props.listData
				state.title = props.title
			})
			const event = {
				detail(item){
					sessionStorage.setItem('pages','3')
					sessionStorage.setItem('pages1',item.status)
					if(item.status == 0){
						proxy.$router.push({name:'detailXy',query:{id:item.protocolId,title:state.title,img:item.avatarPath,time:item.createTime,userName:item.nickName}})
					}else{
						proxy.$router.push({name:'detailXyHis',query:{detail:JSON.stringify(item),title:state.title,img:item.avatarPath,time:item.createTime,userName:item.nickName,protocolName:item.protocolName}})
					}
				}
			}
			return {
				state,
				event
			}
		},
		
	}
</script>
<style>
	.item_list1 .item_title{
		font-weight: normal;
		margin-bottom: 6px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.item_list1 .item_date{
		justify-content: flex-end;
	}
	.item_list1 .item_txt{
		margin: 6px 0;
	}
	.item_txt1{
		color: #000;
		font-size: 14px;
		margin: 6px 0;
	}
</style>