<template>
	<div class="detailXy area-inset-top">
		<nut-navbar @on-click-back="event.back" :title="state.title" style="box-shadow: none;" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<div class="xy_content">
			<div class="xy_top">
				<div class="xy_name" style="display: flex;align-items: center;justify-content: space-between;">
					<span>{{state.protocolName}}</span> 
					<span v-if="state.detail.status == 1">定稿协议</span>
					<span v-else-if="state.detail.status == 2">作废协议</span>
					<span v-else>历史版本{{state.detail.name}}</span>
				</div>
				<div class="xy_user" style="display: flex;align-items: center;justify-content: space-between;">
					<div>
						<span :style="'background-image: url('+state.img+');'"></span>
						<span style="margin: 0;">{{state.userName}}</span>
					</div>
					<span>{{state.detail.createTime}}</span>
				</div>
			</div>
			<div class="xy_list" style="margin-top: 5px;">
				<div class="file_li">
					<span :class="{'file_icon':true,
									'word_icon':['txt','doc','docx','log'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'jpg_icon':['jpg','jpeg','png','gif','bmp','csv'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'video_icon':['mp4','avi','mov','wmv','flv','mkv'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'excel_icon':['xls','xlsx','xlsm','xlsb','xlst'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'ppt_icon':['ppt','pptx','pps','ppsx'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1,
									'pdf_icon':['pdf'].indexOf(state.detail.fileType || state.detail.protocolFileType) != -1
								}"></span>
					<div class="file_cont" style="display: flex;justify-content: space-between;align-items: center;">
						<div class="file_name" style="margin-bottom: 0;width: calc(100% - 85px);">{{state.detail.fileName || state.detail.protocolFileName}}</div><div @click="state.showXy=true" class="xy_btn">查看协议</div>
					</div>
				</div>
				<div @click="event.delOrFinProtocol(2)" class="del_btn" v-if="state.detail.status == 1">作废协议</div>
			</div>
			<div class="xy_bottom">
				<div v-for="item in state.recordList" class="record">
					<div class="record_l" :style="'background-image:url('+item.avatarPath+');'"></div>
					<div class="record_r">
						<div class="record_r_t">
							<div>{{item.nickName}}</div><div>{{item.createTime}}</div>
						</div>
						<div v-if="item.operationType == 0"> <!-- 操作类型     留言 -->
							<div class="xy_tit">批示意见：</div>
							<div class="xy_txt">{{item.leaveMessage}}</div>
						</div>
						<div v-if="item.operationType == 1"> <!-- 操作类型     投票+留言  -->
							<div class="xy_tit"><span v-if="item.voteResult == 0">同意</span><span v-if="item.voteResult == 1">不同意</span>协议：</div>
							<div class="xy_txt">{{item.leaveMessage}}</div>
						</div>
						<div v-if="item.operationType == 2"> <!-- 操作类型     查看 -->
							<div class="xy_tit">查看了协议</div>
						</div>
						<div v-if="item.operationType == 3"> <!-- 操作类型     更新-->
							<div class="xy_tit">更新了协议 {{item.fileName}}》,第{{item.fileVersion}}版</div>
						</div>
						<div v-if="item.operationType == 4"> <!-- 操作类型     定稿-->
							<div class="xy_tit">查看了协议</div>
						</div>
						<div v-if="item.operationType == 5"> <!-- 操作类型     作废 -->
							<div class="xy_tit">查看了协议</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<nut-actionsheet v-model:visible="state.showXy" :menu-items="state.menuItems" @choose="event.choose"></nut-actionsheet>
		<file-p v-if="state.fileBox" :url="state.fileUrl" :type="state.fileType" @fileClose="event.getStatus"></file-p>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, ref, computed, onUnmounted, nextTick} from 'vue';
	import { Dialog, Toast } from '@nutui/nutui';
	import fileP from '@/view/file/iframe'
	import oss from 'ali-oss'
	export default {
		components:{
			fileP
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const uploadFile = ref(null)
			const state = reactive ({
				oss: {},
				fileUrl: '',
				fileBox: false,
				fileType: '',
				height: 0,
				title:'',
				time: '',
				userName:'',
				img:'',
				detail:[],
				recordList: [],
				user:[],
				userList:[],
				showXy: false,
				iosHeight: '44px',
				menuItems: [
					{
						name: '在线预览本协议',
						color: '#46B1EA'
					},
					{
						name: '下载到手机上',
						color: '#46B1EA'
					}
				],
			});
			const loading = (msg)=>{
				Toast.loading(msg)
			} 
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.title = proxy.$route.query.title
				state.userName = proxy.$route.query.userName
				state.img = proxy.$route.query.img
				state.detail = JSON.parse(proxy.$route.query.detail)
				state.protocolName =  proxy.$route.query.protocolName
				event.getProtocolRecordByProtocolFileId()
				if(state.detail.status == 1){
					Toast.fail('此协议已定稿')
					event.getDetail()
				}else if(state.detail.status == 2){
					Toast.fail('此协议已作废')
					event.getDetail()
				}else{
					state.detail.name = state.detail.name.split('历史版本')[1]
				}
			})
			onUnmounted(()=>{
			})
			const event = {
				getDetail(){
					proxy.$axios.getProtocolByProtocolId({protocolId:state.detail.protocolId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.detail.protocolFileName = res.data.data.protocolFileName
							state.detail.protocolFileType = res.data.data.protocolFileType
						}
					})
				},
				choose(item){
					loading('加载中...')
					event.getOssStsConfig(function(){
						if(item.name=="在线预览本协议"){
							event.downOrViewProtocolFile(state.detail.protocolFileId,0)
						}else{
							event.downOrViewProtocolFile(state.detail.protocolFileId,1)
						}
					})
					
				},
				getOssStsConfig(fun){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.oss = res.data.data
							fun()
						}
					})
				},
				downOrViewProtocolFile(id,type){
					proxy.$axios.downOrViewProtocolFile({accessKeyId:state.oss.accessKeyId,accessKeySecret:state.oss.accessKeySecret,securityToken:state.oss.securityToken,operationType:type,protocolFileId:id}).then(res=>{
						if(res.data.errorCode == '00000'){
							if(type == 0){
								state.fileBox = true
								state.fileUrl = res.data.data.urlLink
								state.fileType = res.data.data.protocolFileType
							}else{
								const link = document.createElement('a');
								link.href = res.data.data.urlLink;
								link.setAttribute('download', res.data.data.protocolFileName);
								link.click();
							}
						}
					})
				},
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
					state.fileType = res.fileType
				},
				getProtocolRecordByProtocolFileId(){
					proxy.$axios.getProtocolRecordByProtocolFileId({protocolFileId:state.detail.protocolFileId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.recordList = res.data.data
						}
					})
				},
				loadMore(done){
					state.cur = state.cur+1
					event.getDtList(state.cur,done)
				},
				refresh(done){
					state.cur = 1
					event.getDtList(state.cur,done)
				},
				back(){
					proxy.$router.go(-1)
				},
				delOrFinProtocol(status){
					proxy.$axios.delOrFinProtocol({protocolId:state.detail.protocolId,status:status,userId:state.user.userId}).then((res)=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
						}
					})
				}
			}
			return {
				state,
				event,
				uploadFile
			};
		}
	}
</script>

<style>
	.del_btn{
		width: 90%;
		margin: 10px auto;
		height: 36px;
		line-height: 36px;
		border-radius: 18px;
		font-size: 14px;
		border: 1px solid #f2f2f2;
	}
</style>