<template>
	<div class="search area-inset-top" style="height: 100vh;height: calc(var(--vh, 1vh) * 100);overflow: hidden;">
		<nut-navbar @on-click-back="event.back" title="新增项目信息" :fixed="true" :safe-area-inset-top="true">
		    <template #left>
				<nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		    <template #right>
				<nut-icon name="checklist" size="22" @click="event.save" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<div style="overflow: scroll;height: calc(100vh - 50px);height: calc(var(--vh, 1vh) * 100 - 50px);">
			<div>
				<div class="laberItem">
					<span>项目名称<b style="color:#f00;">*</b></span><div><input @blur="event.projectNameSearch" type="text" placeholder="请输入项目名称" v-model="state.itemValue['projectDetails'].projectName"  /></div>
				</div>
				<div class="laberItem">
					<span>地域</span><div @click="state.dyState = true;state.addressValue=[]"><input type="text" readonly placeholder="请选择地域位置" v-model="state.itemValue['projectDetails'].regionId"  /></div>
				</div>
				<div class="laberItem">
					<span>详细地址</span><div><input type="text" placeholder="请输入详细地址" v-model="state.itemValue['projectDetails'].address"  /></div>
				</div>
				<div class="laberItem">
					<span>联系方式</span><div><input type="tel" placeholder="请输入联系方式" v-model="state.itemValue['projectDetails'].telephone"  /></div>
				</div>
				<div class="laberItem">
					<span>简介</span><div><input type="text" placeholder="请输入简介" v-model="state.itemValue['projectDetails'].introduction"  /></div>
				</div>
				<div class="laberItem">
					<span>项目分组<b style="color:#f00;">*</b></span><div @click="state.fzState = true"><input type="text" readonly placeholder="请选择项目分组" v-model="state.itemValue['projectDetails'].projectCategorylabel"  /><nut-icon name="triangle-down" size="16" style="vertical-align: middle;" color="#777"></nut-icon></div>
				</div>
				<div class="laberItem">
					<span>进度<b style="color:#f00;">*</b></span><div @click="state.jdState = true"><input type="text" readonly placeholder="请选择进度" v-model="state.itemValue['projectDetails'].projectProgresslabel"  /><nut-icon name="triangle-down" size="16" style="vertical-align: middle;" color="#777"></nut-icon></div>
				</div>
				<div class="laberItem" style="height: auto;">
					<span>标签</span>
					<div @click="event.bqClick" style="flex: 1;display: flex;align-items: center;">
						<p class="item_tab_span" style="flex: 1;text-align: left;">
							<span v-for="item in state.projectLabelList" style="display: inline-block;text-align: left;line-height: 20px;">{{item}}</span>
						</p>
						<nut-icon name="arrow-right" size="20" color="#777"></nut-icon>
					</div>
				</div>
				<div class="laberItem">
					<span>融资需求</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input" v-model="state.itemValue['projectCapitalizeInfoList'][0].financingDemand"  />万元
					</div>
				</div>
				<div class="laberItem">
					<span>项目估值</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input" v-model="state.itemValue['projectCapitalizeInfoList'][0].projectValuation"  />万元
					</div>
				</div>
				<div class="laberItem">
					<span>拟投资金额</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input" v-model="state.itemValue['projectCapitalizeInfoList'][0].proposedInvestmentAmount"  />万元
					</div>
				</div>
				<div class="laberItem" style="border: none;">
					<span>占股比例</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input" v-model="state.itemValue['projectCapitalizeInfoList'][0].proportion"  />%
					</div>
				</div>
				<div class="labelTit" style="text-align: left;"><span class="line_icon"></span>简要财务</div>
				<div class="laberItem">
					<span>总资产</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.itemValue['projectFinanceInfoList'][0].totalAssets"  />万元
					</div>
				</div>
				<div class="laberItem">
					<span>净资产</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.itemValue['projectFinanceInfoList'][0].netAssets"  />万元
					</div>
				</div>
				<div class="laberItem">
					<span>营业收入</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.itemValue['projectFinanceInfoList'][0].operatingRevenue"  />万元
					</div>
				</div>
				<div class="laberItem">
					<span>毛利润</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.itemValue['projectFinanceInfoList'][0].grossProfit"  />万元
					</div>
				</div>
				<div class="laberItem">
					<span>净利润</span>
					<div>
						<input type="number" placeholder="请输入数字" @keydown="event.input"  v-model="state.itemValue['projectFinanceInfoList'][0].netProfit"  />万元
					</div>
				</div>
				<div class="laberItem" style="border: none;height: auto;flex-direction: column;text-align: left;">
					<span>备注</span><div style="flex: 1;"><textarea v-model="state.itemValue['projectDetails'].remarks" placeholder="请输入备注"></textarea></div>
				</div>
			</div>
		</div>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.jdState">
			<div class="popup_li" v-for="item in state.JDList" :key="item.value" @click="event.JDClick(item)">
				{{item.label}}
			</div>
		</nut-popup>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.fzState">
			<div class="popup_li" v-for="item in state.FLList" :key="item.value" @click="event.FLClick(item)">
				{{item.label}}
			</div>
		</nut-popup>
		<nut-address
			v-model="itemDY"
		    v-model:visible="state.dyState"
			close-btn-icon="checklist"
		    :province="state.province"
		    :city="state.city"
		    :country="state.country"
			:town="state.town"
		    @change="event.onChange"
		    @close="event.close"
			@close-mask="event.closeMask"
		    custom-address-title="请选择地区"
		></nut-address>
		<div v-if="state.bqState" style="position: fixed;top: 0;left: 0;min-height: 100vh;height: calc(var(--vh, 1vh) * 100);width: 100vw;z-index: 100;">
			<bq-component :go="state.go" :clickFun="event.bqClickOn" :value="state.projectLabelList" :valueId="state.projectLabelListId"></bq-component>
		</div>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs,onActivated,onDeactivated, computed, ref } from 'vue';
	import city from '@/components/city2.json'
	import bqComponent from '@/view/item/label'
	import { Toast, Dialog } from '@nutui/nutui';
	export default {
		name:'itemAdd',
		components:{
			bqComponent
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const onOk = () => {
				state.itemValue['projectDetails'].projectName = ''
				state.nameStatus = false
			}
			const filterCount = computed
			const itemDY = ref([])
			const state = reactive ({
				itemValue:{
				  "projectCapitalizeInfoList": [
					{
					  "financingDemand": '',
					  "projectValuation": '',
					  "proportion": '',
					  "proposedInvestmentAmount": ''
					}
				  ],
				  "projectDetails": {
					"address": "",
					"introduction": "",
					"projectName": "",
					"projectProgress": "",
					"projectProgresslabel": "",
					"regionId": '',
					"region": "",
					"remarks": "",
					"telephone": "",
					"projectCategory": "",
					"projectCategorylabel": ""
				  },
				  "projectFinanceInfoList": [
					{
					  "grossProfit": '',
					  "netAssets": '',
					  "netProfit": '',
					  "operatingRevenue": '',
					  "totalAssets": ''
					}
				  ],
				  "projectLabelList": []
				},
				go: '1',
				projectLabelList:[],
				projectLabelListId:[],
				jdState: false,
				fzState: false,
				dyState: false,
				bqState: false,
				itemDY: [],
				province: [],
				city: [],
				country: [],
				town:[],
				addressValue:[],
				financingDemand:'',
				JDList:[{label:'见面',id:1},{label:'签NDA',id:2},{label:'立项',id:8},{label:'出TS',id:3},{label:'尽调',id:4},{label:'投决',id:5},{label:'投后',id:6},{label:'暂缓',id:7}],
				FLList:[{label:'新项目',id:1},{label:'重点',id:2},{label:'跟进中',id:9},{label:'其他',id:3},{label:'归档',id:4}],
				nameStatus: false
			})
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.province = city
				event.selectProjectProgress()
				event.selectProjectCategory()
			})
			onActivated(()=>{
			})
			onDeactivated(()=>{
			})
			const event = {
				projectNameSearch(fun){
					if(state.nameStatus) return
					state.nameStatus = true
					if(state.itemValue['projectDetails'].projectName){
						proxy.$axios.selectProjectByProjectName({projectName:state.itemValue['projectDetails'].projectName}).then((res)=>{
							if(res.data.errorCode == '00000'){
								if(res.data.data != null && res.data.data.createTime){
									var projectProgress = ''
									for(var i = 0;i<state.JDList.length;i++){
										if(state.JDList[i].value == res.data.data.projectProgress){
											projectProgress = state.JDList[i].label
										}
									}
									Dialog({
									    title: '项目名称重复',
										textAlign:'left',
									    content: "<view class='dag'>项目名称："+res.data.data.projectName+"<br>创建时间："+res.data.data.createTime+"<br>创建人："+res.data.data.userResp.nickName+"<br>项目当前进度："+projectProgress+"</view>",
									    noCancelBtn: true,
										closeOnClickOverlay: false,
										onOk
									})
								}else{
									fun && fun()
								}
							}
						})
					}
				},
				selectProjectCategory(){
					proxy.$axios.selectProjectCategory().then((res)=>{
						if(res.data.errorCode == '00000'){
							state.FLList = res.data.data
						}
					})
				},
				selectProjectProgress(){
					proxy.$axios.selectProjectProgress().then((res)=>{
						if(res.data.errorCode == '00000'){
							state.JDList = res.data.data
						}
					})
				},
				input(el){
					if(el.target.value.length>=14){
						el.target.value = el.target.value.slice(0,14)
					}
				},
				back(){
					proxy.$router.go(-1)
				},
				JDClick(item){
					state.jdState = false
					state.itemValue['projectDetails'].projectProgresslabel = item.label
					state.itemValue['projectDetails'].projectProgress = item.value
				},
				FLClick(item){
					state.fzState = false
					state.itemValue['projectDetails'].projectCategorylabel = item.label
					state.itemValue['projectDetails'].projectCategory = item.value
				},
				bqClick(){
					state.bqState = true
				},
				bqClickOn(id,label){
					state.projectLabelList = label
					state.projectLabelListId = id
					state.bqState = false
				},
				onChange(data){
					state[data.next] = data.value.child
					if(state.addressValue.indexOf(data.value) == -1){
						state.addressValue.push(data.value)
					}
					if(!state[data.next]){
						state.dyState = false
					}
				},
				close(data){
					state.itemValue['projectDetails'].region = data.data.province.id+'|'+data.data.city.id+'|'+data.data.country.id
					state.itemValue['projectDetails'].regionId = data.data.province.name+'|'+data.data.city.name+'|'+data.data.country.name
					// itemDY.value=[data.data.province.id,data.data.city.id,data.data.country.id]
				},
				closeMask(data){
					if(data.closeWay.value == 'cross'){
						if(state.addressValue.length == 2){
							state.itemValue['projectDetails'].region = state.addressValue[0].name+'|'+state.addressValue[1].name
							state.itemValue['projectDetails'].regionId = state.addressValue[0].name+'|'+state.addressValue[1].name
							// itemDY.value=[state.addressValue[0].id,state.addressValue[1].id]
						}else if(state.addressValue.length == 1){
							state.itemValue['projectDetails'].region = state.addressValue[0].name
							state.itemValue['projectDetails'].regionId = state.addressValue[0].name
							// itemDY.value=[state.addressValue[0].id]
						}
					}
				},
				save(){
					event.projectNameSearch(event.saveNext)
				},
				saveNext(){
					if(!state.itemValue['projectDetails'].projectName){
						Toast.warn('请输入项目名称')
						return
					}
					let regName = /[\ud800-\udbff][\udc00-\udfff]/
					if(regName.test(state.itemValue['projectDetails'].projectName)){
						Toast.warn('项目名称不能是特殊字符')
						return
					}
					if(!state.itemValue['projectDetails'].projectCategorylabel){
						Toast.warn('请选择项目分组')
						return
					}
					if(!state.itemValue['projectDetails'].projectProgresslabel){
						Toast.warn('请选择项目进度')
						return
					}
					let reg = /^\d{1,10}$|^\d{1,10}[.]\d{1,4}$/
					if(state.itemValue['projectCapitalizeInfoList'][0].financingDemand && !reg.test(state.itemValue['projectCapitalizeInfoList'][0].financingDemand)){
						Toast.warn('请输入正确的融资需求')
						return
					}
					if(state.itemValue['projectCapitalizeInfoList'][0].projectValuation && !reg.test(state.itemValue['projectCapitalizeInfoList'][0].projectValuation)){
						Toast.warn('请输入正确的项目估值')
						return
					}
					if(state.itemValue['projectCapitalizeInfoList'][0].proposedInvestmentAmount && !reg.test(state.itemValue['projectCapitalizeInfoList'][0].proposedInvestmentAmount)){
						Toast.warn('请输入正确的拟投资金额')
						return
					}
					if(state.itemValue['projectFinanceInfoList'][0].totalAssets && !reg.test(state.itemValue['projectFinanceInfoList'][0].totalAssets)){
						Toast.warn('请输入正确的总资产')
						return
					}
					if(state.itemValue['projectFinanceInfoList'][0].netAssets && !reg.test(state.itemValue['projectFinanceInfoList'][0].netAssets)){
						Toast.warn('请输入正确的净资产')
						return
					}
					if(state.itemValue['projectFinanceInfoList'][0].operatingRevenue && !reg.test(state.itemValue['projectFinanceInfoList'][0].operatingRevenue)){
						Toast.warn('请输入正确的营业收入')
						return
					}
					if(state.itemValue['projectFinanceInfoList'][0].grossProfit && !reg.test(state.itemValue['projectFinanceInfoList'][0].grossProfit)){
						Toast.warn('请输入正确的毛利润')
						return
					}
					if(state.itemValue['projectFinanceInfoList'][0].netProfit && !reg.test(state.itemValue['projectFinanceInfoList'][0].netProfit)){
						Toast.warn('请输入正确的净利润')
						return
					}
					delete state.itemValue['projectDetails'].projectProgresslabel
					delete state.itemValue['projectDetails'].projectCategorylabel
					delete state.itemValue['projectDetails'].region
					if(state.projectLabelListId.length){
						state.projectLabelListId.forEach(item=>{
							state.itemValue['projectLabelList'].push({"labelId":item})
						})
					}
					proxy.$axios.addProject({...state.itemValue,userId:state.user.userId}).then(res =>{
						if(res.data.errorCode == '00000'){
							proxy.$router.push('/')
						}
					})
				}
			}
			return { state,event,toRefs,itemDY,onOk };
		}
	}
</script>

<style>
	.dag{
		line-height: 30px;
		font-size: 14px;
	}
</style>