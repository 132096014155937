<template>
	<div class="mechanism area-inset-top">
		<div class="tab" style="background-color: #5484ea;overflow: hidden;padding-top: env(safe-area-inset-top);">
			<div class="item_top" style="padding-bottom: 10px;">
				<div>
					<input type="text" v-model="state.organizationName" class="input_box" @change="event.serach" placeholder="搜索,可输入机构名称"> 
					<img style="vertical-align: middle;" @click="event.serach" class="serach_icon" src="../../assets/imgs/icon_home_search.png" alt="">
				</div>
				<span class="me_total">共{{state.total}}家机构</span>
			</div>
		</div>
		<div class="item_mechanism" id="item_mechanism">
			<nut-infiniteloading
				  load-txt="加载中..."
				  pull-txt="   "
				  load-more-txt="没有啦～"
				  container-id="item_mechanism"
				  :use-window="false"
				  :is-open-refresh="true"
				  :has-more="state.customHasMore"
				  load-icon="loading1"
				  pull-icon="loading1"
				  @load-more="customLoadMore"
				  @refresh="refresh"
				>
				<jg-component :listData="state.list"></jg-component>
			</nut-infiniteloading>
		</div>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs,watch, onCreated,onActivated, onBeforeMount } from 'vue';
	import {useRouter} from 'vue-router'
	import xmComponent from '@/view/item/list'
	import jgComponent from '@/view/mechanism/list'
	export default {
		name: 'index',
		components:{
			xmComponent,
			jgComponent
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const router = useRouter()
			const state = reactive ({
				user: {},
				customHasMore: true,
				cur: 1,
				organizationName: ''
			});
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				event.getList(state.cur,10,function(){})
			});
			const customLoadMore = done => {
				state.cur++
				event.getList(state.cur,10,done)
			}
			const refresh = done => {
				state.cur = 1
				state.list = []
				event.getList(state.cur,10,done)
			}
			const event = {
				getList(cur,size,fun){
					proxy.$axios.listQueryPage({currentPage:cur,pageSize:size,userId:state.user.userId,userRole:state.user.role,organizationName:state.organizationName}).then(res =>{
						if(res.data.errorCode == '00000'){
							let obj = res.data.data
							if(obj.pages <= cur){
								state.customHasMore = false
							}else{
								state.customHasMore = true
							}
							if(cur == 1){
								state.list = obj.list
							}else{
								state.list = state.list.concat(obj.list)
							}
							state.total = obj.total
							fun()
						}
					})
				},
				serach(){
					state.cur = 1
					state.list = []
					event.getList(state.cur,10,function(){})
				},
				detail(item){
					proxy.$router.push({name:'MechanismDetail',query:{id:item.organizationId}})
				},
			}
			return { state,event,toRefs,customLoadMore,refresh };
		}
	};
</script>

<style>
	.mechanism{
		background-color: #f2f2f2;
	}
	.mechanism .item_top{
		padding: 10px;
	}
	.me_total{
		font-size: 12px;
	}
	.item_mechanism{
		height: calc(100vh - 100px);
		height: calc(var(--vh, 1vh) * 100 - 100px);
		overflow: scroll;
	}
</style>