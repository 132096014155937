<template>
  <div class="mechanism_li" @click="event.detail(item)" v-for="(item,index) in state.listObj">
  	<div class="me_li_l">
  		<div>
  			<img :src="item.logoAddress" alt="" v-if="item.logoAddress">
  			<span v-else>{{item.name}}</span>
  		</div>
  	</div>
  	<div class="me_li_r">
  		<div class="me_li_cont">
  			<div class="me_name">{{item.name}}</div>
  			<div class="me_time">关联{{item.relatedProjectsCount}}个项目</div>
  		</div>
  		<div class="item_tab_span" style="text-align: left; overflow-x: scroll; white-space: nowrap;">
  			<span style="margin-bottom:5px;" v-for="(label,labelIndex) in item.labelList">{{label.labelName}}</span>
  		</div>
  	</div>
  </div>
</template>
<script>
	import { reactive, onMounted, getCurrentInstance, onUpdated, onBeforeUnmount, toRefs,ref } from 'vue'
	import { Toast } from '@nutui/nutui';
	export default{
		props:{
			listData: Array
		},
		setup(props,context){
			const { proxy } = getCurrentInstance()
			const state = reactive({
				listObj: {},
				id: '',
				user: [],
				oss: {}
			})
			const loading = (msg)=>{
				Toast.loading(msg)
			} 
			onMounted(()=>{
				state.listObj = props.listData
			})
			onUpdated(()=>{
				state.listObj = props.listData
			})
			const event = {
				detail(item){
					proxy.$router.push({name:'MechanismDetail',query:{id:item.organizationId}})
				},
			}
			return {
				state,
				event
			}
		},
		
	}
</script>

<style>
	.mechanism_li{
		display: flex;
		padding: 10px;
		background-color: #fff;
		margin-bottom: 10px;
	}
	.me_li_l{
		width: 50px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		border-radius: 50px;
		background-color: rgb(84, 132, 234);
		color: #fff;
		margin-right: 10px;
		overflow: hidden;
	}
	.me_li_l>div{
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		border-radius: 20px;
		overflow: hidden;
	}
	.me_li_l img{
		width: 100%;
		height: 100%;
	}
	.me_li_r{
		flex: 1;
		overflow: hidden;
	}
	.me_li_cont{
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}
	.me_time{
		font-size: 12px;
	}
</style>